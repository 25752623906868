
import React from 'react'
import { Input, Button } from 'antd'
import { CloudDownloadOutlined } from '@ant-design/icons'


const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,
  },
  heading: {
    flex: 1,
    margin: 0,
  },
}

type Props = {
  searchTerm: string,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onClickExport: () => void,
  onClickNewRamsSigning: () => void,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      searchTerm,
      onChangeSearch,
      onSubmitSearch,
      onClickExport,
      onClickNewRamsSigning,
    } = this.props

    return (

      <div class="_header_bar">

        <h1>
          Rams to Sign
        </h1>

      </div>

    )

  }


  /*

  <div style={{ width: 10 }} />
  <Button onClick={onClickExport}>
    <CloudDownloadOutlined style={{ fontSize: 16, marginTop: 3 }} />
  </Button>

  <div style={{ width: 10 }} />

  <Button type='primary' onClick={onClickNewRamsSigning}>
    New RamsSigning
  </Button>

  <Input.Search
    value={searchTerm}
    onChange={event => onChangeSearch(event.target.value)}
    onSearch={onSubmitSearch}
    placeholder='Search'
    enterButton
    style={{ width: 250 }}
  />
  */
}
