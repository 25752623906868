import {message} from 'antd'
import {updateRamsSigning} from '../../../HttpRequests/ramsSignings'

export default (values) => async (dispatch, getState) => {

    const {id} = values
    const data = {
        id: id,
        signed: 1,
    }
console.log(data);
    const response = await updateRamsSigning({id, data})

    const ramsSigning = response.data

    message.success('Signed - you will receive a signed copy in your email, or you can download from your listing.')

    return ramsSigning

}
