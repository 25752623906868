exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._24cPkQoTgE3m-Rs_2Y4YZL tbody tr {\n  cursor: pointer;\n}\n\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/RamsSigning/Components/Table.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;CACjB","file":"Table.css","sourcesContent":[".table tbody tr {\n  cursor: pointer;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "_24cPkQoTgE3m-Rs_2Y4YZL"
};