exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1a_aoX7SCrkoNpeBWKX-wo {\n  padding: 10px;\n}\n\n._3seDHis-xTU3Gd2F5igFsf {\n  display: flex;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n\n._1ZJLagKKd3z0KOYZer4wIr {\n  height: 25px;\n  display: flex;\n  align-items: flex-end;\n}\n\n.IBjSg54mGZ-Ny8AZamjck {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/Planner/Components/ProjectCellModal/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;CACf;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,iBAAiB;EACjB,oBAAoB;CACrB;;AAED;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;CACvB;;AAED;EACE,cAAc;EACd,+BAA+B;EAC/B,oBAAoB;EACpB,iBAAiB;EACjB,oBAAoB;CACrB","file":"styles.css","sourcesContent":[".wrapper {\n  padding: 10px;\n}\n\n.subcontractor {\n  display: flex;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n\n.addSubcontractor {\n  height: 25px;\n  display: flex;\n  align-items: flex-end;\n}\n\n.inputRow {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1a_aoX7SCrkoNpeBWKX-wo",
	"subcontractor": "_3seDHis-xTU3Gd2F5igFsf",
	"addSubcontractor": "_1ZJLagKKd3z0KOYZer4wIr",
	"inputRow": "IBjSg54mGZ-Ny8AZamjck"
};