exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2kB3CIft_76b13ZV0bO0dO {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.f97CEaVZ213rmAGTglocB {\n  flex: 1;\n}\n\n._2kD1Pb_bh3G1i1pCS18IAI {\n  height: 100%;\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 15px;\n}\n\n.kvi8b8NdTOp_DqaJw8TzB {\n  width: 75px;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/EstimateView/Components/Optionals/Components/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,oBAAoB;CACrB;;AAED;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;CACb","file":"Header.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.spacer {\n  flex: 1;\n}\n\n.headings {\n  height: 100%;\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 15px;\n}\n\n.cell {\n  width: 75px;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2kB3CIft_76b13ZV0bO0dO",
	"spacer": "f97CEaVZ213rmAGTglocB",
	"headings": "_2kD1Pb_bh3G1i1pCS18IAI",
	"cell": "kvi8b8NdTOp_DqaJw8TzB"
};