export default [
    {"key":"Stepladders","value":"Stepladders"},
    {"key":"Podium","value":"Podium"},
    {"key":"Mobile Tower","value":"Mobile Tower"},
    {"key":"Scaffold Tower","value":"Scaffold Tower"},
    {"key":"Peco Lift","value":"Peco Lift"},
    {"key":"Cherry Picker","value":"Cherry Picker"},
    {"key":"Scissor Lift","value":"Scissor Lift"},
    {"key":"Hop Up","value":"Hop Up"},
]
