
import { getEmails } from '../../../HttpRequests/emails'
import { setEmails } from '../State/emails'
import { setFetching } from '../State/fetching'
import { setPagination } from '../State/pagination'
import handleException from '../../../Core/Helpers/handleException'


export default () => async (dispatch, getState) => {

  const { searchTerm, filters, sorting, pagination } = getState().screens.Emails
  const params = {

  }

  if (searchTerm) params.search = searchTerm

  if (filters.status !== 'All') {
    params['filter[status]'] = filters.status
  }

  params['filter[opened]'] = filters.opened

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

    console.log('E',getState().screens.Emails);
    let af = getState().screens.Emails.additionalFilter;
    Object.keys(af).forEach(function(key) {
        params['filter[' + key + ']'] = af[key];
    });

  dispatch(setFetching(true))

  try {

    const response = await getEmails({ params })
    dispatch(setEmails(response.data.data))

    dispatch(setPagination({
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
      total: response.data.pagination.total,
    }))

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error)

  }

}
