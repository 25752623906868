exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3sBckCQ7NRhedOzUr7xnQp {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.Pprr56wrky8irLfkH8Q5K {\n  flex: 1;\n  display: flex;\n}\n\n._3jirbB6gPRFk8folAafLLA {\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/SubProject/Components/SubValuations/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;CACC","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.right {\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3sBckCQ7NRhedOzUr7xnQp",
	"left": "Pprr56wrky8irLfkH8Q5K",
	"right": "_3jirbB6gPRFk8folAafLLA"
};