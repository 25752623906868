exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3G8_bL-BktUZI6R0Roztp0 {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._1z0xUpqVh9NVQPDL7u7mtu {\n  flex: 1;\n  display: flex;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/SubProject/Components/SubProjects/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3G8_bL-BktUZI6R0Roztp0",
	"left": "_1z0xUpqVh9NVQPDL7u7mtu"
};