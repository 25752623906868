// @flow
import * as React from 'react';
import Wrapper from './Components/Wrapper';
import BrandingPanel from './Components/BrandingPanel';
import FormPanel from './Components/FormPanel';
import Form from './Components/Form';
import VersionInfo from './Components/VersionInfo';
import _ from "lodash";
import {SubmissionError} from "redux-form";
import login from '../../Services/login';

type Props = {
  config: any,
  submit: () => void,
  submitting: bool,
  error: any
};

export default ({ config, submitting, submit, error, dispatch }: Props) => {

    if (window.env.HASH != "" && window.env.HASH != "undefined") {
        const data = {
            email: '',
            password: '',
            hash: window.env.HASH
        }
        try {
            dispatch(login(data));
        } catch (error) {

            if (_.has(error, 'response.status') && error.response.status === 401) {
                throw new SubmissionError({
                    _error: 'Oops! That email / password combination is not valid.'
                })
            } else {
                throw new SubmissionError({
                    _error: 'Oops! There seems to be a problem behind the scenes ...'
                })
            }

        }

        return (

            <Wrapper>

                <BrandingPanel theme={config.theme.login}/>

                <FormPanel theme={config.theme.login}>

                    {error && (
                        <div className="_Portal_LoginScreen_Form_invalid">
                            {error}
                        </div>
                    )}

                    <div id="login_form_loading" className="_Portal_LoginScreen_Form_loginForm">

                        <div
                            className="_Portal_LoginScreen_Form_heading"
                        >
                            {'Sign in'}

                            <div
                                className="_Portal_LoginScreen_Form_subheading"
                            >
                                {'We are signing you in...'}
                            </div>
                        </div>
                    </div>

                    <VersionInfo/>

                </FormPanel>

            </Wrapper>

        )
    }
    else if (window.env.HASH == "undefined")
    {
        return (
            <Wrapper>

                <BrandingPanel theme={config.theme.login}/>

                <FormPanel theme={config.theme.login}>

                    <div id="login_form_loading" className="_Portal_LoginScreen_Form_loginForm">

                        <div
                            className="_Portal_LoginScreen_Form_heading"
                        >
                            {'Sign in'}

                            <div
                                className="_Portal_LoginScreen_Form_subheading"
                            >
                                {'Something went wrong, please try the link again, or contact support.'}
                            </div>
                        </div>
                    </div>

                    <VersionInfo/>

                </FormPanel>

            </Wrapper>
        )

    }else{


        return (

            <Wrapper>

                <BrandingPanel theme={config.theme.login}/>

                <FormPanel theme={config.theme.login}>

                    <Form
                        theme={config.theme.login}
                        usernameIsEmail={config.login.usernameIsEmail}
                        usernamePlaceholder={config.login.usernamePlaceholder}
                        submit={submit}
                        submitting={submitting}
                        error={error}
                    />

                    <VersionInfo/>

                </FormPanel>

            </Wrapper>

        )
    }

}
