// @flow
import React from 'react';
import { Field } from 'redux-form';

type Props = {
  theme: any,
  usernameIsEmail: boolean,
  usernamePlaceholder: string,
  submit: () => void,
  submitting: bool,
  error: ?any
};

export default ({ theme, usernameIsEmail, usernamePlaceholder, submit, submitting, error }: Props) => {


  // overide styles from the theme config

  const styles = {

    heading: {
      color: theme.headingColor
    },

    subheading: {
      color: theme.subheadingColor
    },

    label: {
      color: theme.labelTextColor
    },

    input: {
      color: theme.inputTextColor,
      borderColor: theme.inputBorderColor
    },

    submitButton: {
      backgroundColor: theme.submitButtonColor,
      color: theme.submitButtonTextColor
    },

  }

  return (

    <div>


        <div id="login_form" className="_Portal_LoginScreen_Form_loginForm">

          <div
            className="_Portal_LoginScreen_Form_heading"
            style={styles.heading}
          >

            {'Sign in to the LeadPM.io Portal.'}

            <div
              className="_Portal_LoginScreen_Form_subheading"
              style={styles.subheading}
            >
              {'Enter your details below.'}
            </div>

          </div>

          {error && (
            <div className="_Portal_LoginScreen_Form_invalid">
              {error}
            </div>
          )}

          <div>

            <label
              htmlFor="username"
              className="_Portal_LoginScreen_Form_label"
              style={styles.label}
            >
              {usernameIsEmail ? 'Email Address' : 'Username'}
            </label>

            <Field
              id="username"
              name="username"
              component="input"
              className="_Portal_LoginScreen_Form_input _Portal_LoginScreen_Form_usernameField"
              style={styles.input}
              placeholder={usernamePlaceholder}
              autoComplete="off"
              disabled={submitting}
              onKeyPress={e => e.which === 13 && submit()}
            />

          </div>

          <div>

            <label
              htmlFor="password"
              className="_Portal_LoginScreen_Form_label"
              style={{ color: theme.labelTextColor }}
            >
              {'Password'}
            </label>

            <Field
              id="password"
              name="password"
              component="input"
              type="password"
              className="_Portal_LoginScreen_Form_input _Portal_LoginScreen_Form_passwordField"
              style={styles.input}
              placeholder="Enter your password"
              disabled={submitting}
              onKeyPress={e => e.which === 13 && submit()}
            />

          </div>

          <button
            id="loginButton"
            className="_Portal_LoginScreen_Form_submitButton"
            style={styles.submitButton}
            onClick={submit}
            type="submit"
            disabled={submitting}
          >
            {submitting ? 'Authenticating' : 'Sign In'}
          </button>


        </div>
    </div>
  )

}

