exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1lxp1Hm8yaIAUawGup3154 {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.Mai6lSFPZHuHVR9L6gYu1 {\n  margin-right: auto;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Modals/ExportStatistics/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;CAC3B;;AAED;EACE,mBAAmB;CACpB","file":"Component.css","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.deleteButton {\n  margin-right: auto;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "_1lxp1Hm8yaIAUawGup3154",
	"deleteButton": "Mai6lSFPZHuHVR9L6gYu1"
};