import React from 'react'
import PropTypes from 'prop-types'
import { Popover, Button, Tag, Tooltip } from 'antd'
import {
  BellFilled,
  BellOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PoundCircleFilled,
  PoundCircleOutlined,
  ScheduleOutlined,
  UserOutlined,
  UserSwitchOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import numeral from 'numeral'
import moment from 'moment'
import classNames from 'classnames'
import { getUser } from 'Common'
import projectStatuses from '../../Utils/projectStatuses'
import projectPhases from '../../Utils/projectPhases'
import styles from './styles.css'

import ClientSiteDetails from '../ClientSiteDetails'
import CellInfo from '../CellInfo'

const Line = ({ content }) => <div style={{ height: '22px', lineHeight: '22px', fontSize: 13, fontWeight: 500 }}>{content}</div>

const formatRamsDate = date => {
  const $date = moment(date)
  return `${$date.format('DD/MM/YYYY')} (${$date.fromNow()})`
}

const ProjectCell = ({ record, onOmit, onUnomit }) => (

	<div className={styles.cell}>
    <div className={styles.cellBody}>

      <div style={{ flex: 1 }}>

        <div style={{ fontWeight: 600 }}>Project {record.number}</div>
        <div>{record.client_site.client.name}</div>
        <Popover
          title={record.client_site.name}
          placement='bottom'
          content={<ClientSiteDetails clientSite={record.client_site} />}
        >
          <div style={{ color: '#9e9e9e' }}>{record.client_site.name}</div>
        </Popover>
        <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
          <Tag color={projectStatuses[record.status.key]}>{record.status.title}</Tag>
          <Tag color={projectPhases[record.phase.key]}>{record.phase.title}</Tag>
        </div>
        <div style={{ height: 35, display: 'flex', alignItems: 'center' }}>
          <Tooltip title='Finishing Schedule'>
            <div className={classNames(styles.widget, styles.file, { [styles.uploaded]: record.file_finishing_schedule })}>FS</div>
          </Tooltip>
          <Tooltip title='Programme'>
            <div className={classNames(styles.widget, styles.file, { [styles.uploaded]: record.file_programme })}>Pr</div>
          </Tooltip>
          <Tooltip title='Induction Required'>
            <div className={classNames(styles.widget, styles.flag, { [styles.checked]: record.induction_required })}>IR</div>
          </Tooltip>
          <Tooltip title='Weekly Returns'>
            <div className={classNames(styles.widget, styles.flag, { [styles.checked]: record.weekly_returns })}>WR</div>
          </Tooltip>
          <Tooltip title='Client Terms & Conditions'>
            <div className={classNames(styles.widget, styles.flag, { [styles.checked]: record.terms_and_conditions })}>TC</div>
          </Tooltip>
        </div>

      </div>

      <div style={{ width: 140, paddingLeft: 10 }}>

        <Tooltip title='Contracts Manager'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserOutlined style={{ marginRight: 5 }} />
            <Line content={record.contracts_manager ? record.contracts_manager.full_name : 'N/A'} />
          </div>
        </Tooltip>
        {!!record.other_contracts_manager && (
          <Tooltip title='Other Contracts Manager'>
            <div style={{ display: 'flex', alignItems: 'center', color: '#9e9e9e' }}>
              <UserSwitchOutlined style={{ marginRight: 5 }} />
              <Line content={record.other_contracts_manager.full_name} />
            </div>
          </Tooltip>
        )}
        <Tooltip title='Start Date'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CalendarOutlined style={{ marginRight: 5 }} />
            <Line content={record.start_date ? moment(record.start_date).format('DD MMM YY') : 'TBC'} />
          </div>
        </Tooltip>
        <Tooltip title='Last Valuation Date'>
          <div style={{ display: 'flex', alignItems: 'center', color: 'orange' }}>
            <ScheduleOutlined style={{ marginRight: 5 }} />
            <Line content={record.last_valuation_date ? moment(record.last_valuation_date).format('DD MMM YY') : '-'} />
          </div>
        </Tooltip>
        <Tooltip title='Value'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PoundCircleOutlined style={{ marginRight: 5 }} />
            <Line content={`£${numeral(record.value_total).format('0,0.00')}`} />
          </div>
        </Tooltip>
        <Tooltip title='Value Remaining'>
          <div style={{ display: 'flex', alignItems: 'center', color: record.value_remaining > 0 ? 'orange' : '#9e9e9e' }}>
            <PoundCircleFilled style={{ marginRight: 5 }} />
            <Line content={`£${numeral(record.value_remaining).format('0,0.00')}`} />
          </div>
        </Tooltip>
        {Object.keys(record.rams_summary).map(type => (
          <React.Fragment key={type}>
            {record.rams_summary[type].status === 'Missing' && (
              <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                <CloseCircleOutlined style={{ marginRight: 5 }} />
                <Line content={`${type}: Missing`} />
              </div>
            )}
            {record.rams_summary[type].status === 'Current' && (
              <Tooltip title={`Expires: ${formatRamsDate(record.rams_summary[type].date)}`}>
                <div style={{ display: 'flex', alignItems: 'center', color: 'green' }}>
                  <CheckCircleOutlined style={{ marginRight: 5 }} />
                  <Line content={`${type}: Current`} />
                </div>
              </Tooltip>
            )}
            {record.rams_summary[type].status === 'Expiring' && (
              <Tooltip title={`Expires: ${formatRamsDate(record.rams_summary[type].date)}`}>
                <div style={{ display: 'flex', alignItems: 'center', color: 'orange' }}>
                  <ExclamationCircleOutlined style={{ marginRight: 5 }} />
                  <Line content={`${type}: Expiring`} />
                </div>
              </Tooltip>
            )}
            {record.rams_summary[type].status === 'Future' && (
              <Tooltip title={`Starts: ${formatRamsDate(record.rams_summary[type].date)}`}>
                <div style={{ display: 'flex', alignItems: 'center', color: '#9e9e9e' }}>
                  <MinusCircleOutlined style={{ marginRight: 5 }} />
                  <Line content={`${type}: Future`} />
                </div>
              </Tooltip>
            )}
            {record.rams_summary[type].status === 'Expired' && !record.rams_muted && (
              <Tooltip title={`Expired: ${formatRamsDate(record.rams_summary[type].date)}`}>
                <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                  <WarningOutlined style={{ marginRight: 5 }} />
                  <Line content={`${type}: Expired`} />
                </div>
              </Tooltip>
            )}
          </React.Fragment>
        ))}

      </div>

    </div>

    {!!record.planner_notes && <CellInfo content={record.planner_notes} />}

    <div className={styles.buttons}>
      {record.project_omitters.map(row => row.id).indexOf(getUser().id) !== -1 ? (
        <Tooltip title='Subscribe'>
          <Button
            icon={<BellOutlined />}
            shape='circle'
            onClick={e => {
              e.stopPropagation()
              onUnomit(record.id)
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip title='Unsubscribe'>
          <Button
            icon={<BellFilled />}
            shape='circle'
            onClick={e => {
              e.stopPropagation()
              onOmit(record.id)
            }}
          />
        </Tooltip>
      )}
    </div>

  </div>

)

Line.propTypes = {
  content: PropTypes.any.isRequired,
}

ProjectCell.propTypes = {
  record: PropTypes.object.isRequired,
  onOmit: PropTypes.func.isRequired,
  onUnomit: PropTypes.func.isRequired,
}

export default ProjectCell
