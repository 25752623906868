/* eslint-disable no-nested-ternary */
import { initialize } from 'redux-form'
import {findRamsSigning, updateRamsSigning} from '../../../HttpRequests/ramsSignings'
import { getProps } from '../props'
import { FORM_NAME } from '../Form'

export default () => async (dispatch) => {

  const { id } = getProps()

  const params = {
    include: '',
    append: '',
  }

  params.include = [
      'project',
      'project.clientSite',
      'project.clientSite.client',
      'subcontractor',
      'ramsRecord'
  ].join();


  if (id) {

    const response = await findRamsSigning({ id, params })
    const ramsSigning = response.data

    dispatch(initialize(FORM_NAME, {
      ...ramsSigning,
    }))

    const data = {
      viewed: 1,
    }

    const viewedResponse = await updateRamsSigning({ id, data })


      /*
      } else {

        dispatch(initialize(FORM_NAME, {
          //
        }))
        */
  }

}
