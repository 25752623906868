exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3QyinCe5QTGDExiG4b5avf {\n  width: 20px;\n  height: 20px;\n  display: inline-block;\n  border-radius: 50%;\n  line-height: 18px;\n  text-align: center;\n  font-size: 8px;\n  font-weight: 600;\n  color: #999999;\n  border: 1px solid #999999;\n  margin-right: 3px;\n}\n\n._3SrsGtVnIfLpUTKVBKZkK {\n  color: white;\n  background-color: rgb(64, 169, 255) !important;\n  border: 1px solid rgb(64, 169, 255);\n}\n\n._1jYLYoMQW79lrw5I2J3NkE {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  cursor: pointer;\n}\n\n._2Vp358TNmZjyVk8lnfj_C2 {\n  display: flex;\n  align-items: flex-start;\n  font-size: 12px;\n  padding: 10px 10px;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/Planner/Components/ProjectCellShrunk/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,0BAA0B;EAC1B,kBAAkB;CACnB;;AAED;EACE,aAAa;EACb,+CAA+C;EAC/C,oCAAoC;CACrC;;AAED;EACE,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,+BAA+B;EAC/B,gBAAgB;CACjB;;AAED;EACE,cAAc;EACd,wBAAwB;EACxB,gBAAgB;EAChB,mBAAmB;CACpB","file":"styles.css","sourcesContent":[".file {\n  width: 20px;\n  height: 20px;\n  display: inline-block;\n  border-radius: 50%;\n  line-height: 18px;\n  text-align: center;\n  font-size: 8px;\n  font-weight: 600;\n  color: #999999;\n  border: 1px solid #999999;\n  margin-right: 3px;\n}\n\n.uploaded {\n  color: white;\n  background-color: rgb(64, 169, 255) !important;\n  border: 1px solid rgb(64, 169, 255);\n}\n\n.cell {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  cursor: pointer;\n}\n\n.cellBody {\n  display: flex;\n  align-items: flex-start;\n  font-size: 12px;\n  padding: 10px 10px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"file": "_3QyinCe5QTGDExiG4b5avf",
	"uploaded": "_3SrsGtVnIfLpUTKVBKZkK",
	"cell": "_1jYLYoMQW79lrw5I2J3NkE",
	"cellBody": "_2Vp358TNmZjyVk8lnfj_C2"
};