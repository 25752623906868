
import React from 'react'
import {Popover, Table, Tag} from 'antd'
import {CheckOutlined, CloseOutlined, InfoCircleOutlined} from '@ant-design/icons'

import styles from './Table.css'
import moment from "moment";


type Props = {
    emails: [],
    componentView: boolean,
    additionalFilter: {},
    fetching: boolean,
    pagination: {},
    onChangeTable: () => void,
    onClickRow: () => void,
}

export default class extends React.PureComponent<Props> {

    render() {

        const {
            emails,
            componentView,
            fetching,
            additionalFilter,
            pagination,
            onChangeTable,
            onClickRow,
        } = this.props

        const columns = [
            {
                title: 'Subject',
                dataIndex: 'subject',
                key: 'subject',
                sorter: false,
                render: (text, record) => record.subject,
            },
            {
                title: 'To',
                dataIndex: 'to',
                key: 'to',
                sorter: false,
                render: (text, record) => record.to,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: false,
                render: (text, record) => (
                    <div>
                        {record.status === 'hold' && <Tag color='red'>Hold</Tag>}
                        {record.status === 'pending' && <Tag color='blue'>Pending</Tag>}
                        {record.status === 'deleted' && <Tag color='grey'>Deleted</Tag>}
                        {record.status === 'sent' && <Tag color='green'>Sent</Tag>}
                    </div>
                ),
            },
            {
                title: 'Opened',
                dataIndex: 'opened',
                sorter: true,
                render: (text, record) => (
                    <div>
                        {record.opened === '0' && <Tag color='red'>No</Tag>}
                        {record.opened === '1' && <Tag color='green'>Yes</Tag>}
                        {record.opened === 0 && <Tag color='red'>No</Tag>}
                        {record.opened === 1 && <Tag color='green'>Yes</Tag>}
                    </div>
                ),
            },
            {
                title: 'Created',
                key: 'created_at',
                sorter: true,
                render: (text, record) => (
                    <div>
                        {record.created_at ? moment(record.created_at).format('DD MMM YYYY HH:mm:ss') : ' '}
                    </div>
                ),
            },
            {
                title: 'Sent at',
                key: 'sent_at',
                sorter: true,
                render: (text, record) => (
                    <div>
                        {record.sent_at ? moment(record.sent_at).format('DD MMM YYYY HH:mm:ss') : ' '}
                    </div>
                ),
            },
            {
                title: ' ',
                key: 'body',
                fixed: 'left',
                sorter: false,
                width: 35,
                render: (text, record) => record.body && (
                    <Popover
                        title="Body"
                        trigger="hover"
                        content={(
                            <div style={{maxWidth: 300}}>
                                {record.body}
                            </div>
                        )}
                        style={{maxWidth: 300}}
                    >
                        <InfoCircleOutlined style={{color: '#1890FF', fontSize: 18}}/>
                    </Popover>
                )
            },


        ]

        /*
            {
                title: 'CC',
                dataIndex: 'cc',
                key: 'cc',
                sorter: false,
                render: (text, record) => record.cc,
            },
            {
                title: 'BCC',
                dataIndex: 'bcc',
                key: 'bcc',
                sorter: false,
                render: (text, record) => record.bcc,
            },
         */
//        const scroll = { x: columns.reduce((total, column) => total + column.width, 150) };
//              scroll={scroll}
//
        if (componentView)
        {
            const scroll = { x: "max-content" };
        }else{
            const scroll = null;
        }

        return (
            <Table
                scroll={scroll}
                columns={columns}
                dataSource={emails}
                rowKey='id'
                onChange={onChangeTable}
                bordered
                loading={fetching}
                className={styles.table}
                onRow={(record) => ({
                    onClick: () => onClickRow(record.id),
                })}
                style={{ whiteSpace: 'pre'}}
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    current: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
            />
        )

    }

}


