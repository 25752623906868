import React from 'react'
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { get, includes } from 'lodash';
import { user as userIcon } from 'react-icons-kit/icomoon/user';
import { menu as menuIcon } from 'react-icons-kit/icomoon/menu';
import {lifebuoy} from 'react-icons-kit/icomoon/lifebuoy'
import { getUser, getTeamUrl, getCurrentTeam, getTeams, goToTeam } from 'Common';

const currentTeam = getCurrentTeam()

const selectPathname = state => state.router.location.pathname;

const basicButtons = [
  'estimates',
  'orders',
  'valuations',
  'invoices',
  'clients',
  'logout',
]

const basicPlusButtons = [
  'settings',
  'emails',
  ...basicButtons,
]

const adminButtons = [
  'users',
  ...basicPlusButtons,
]

const advancedButtons = [
    'dashboard',
    'projects',
    'estimates',
    'orders',
    'valuations',
    'invoices',
    'retention_payments',
    'clients',
    'subcontractors',
    'timesheets',
    'profit_and_loss',
    'insights',
    'users',
    'settings',
    'emails',
    'logout',
]
const premiumButtons = [
    'dashboard',
    'projects',
    'estimates',
    'orders',
    'valuations',
    'invoices',
    'retention_payments',
    'rams_records',
    'clients',
    'subcontractors',
    'planner',
    'timesheets',
    'profit_and_loss',
    'insights',
    'users',
    'settings',
    'emails',
    'logout',
]

const buttonsByType = {
  'Basic': basicButtons,
  'Basic+': basicPlusButtons,
  'Admin': adminButtons,
  'Advanced': advancedButtons,
  'Premium': premiumButtons,
}

const user = getUser()

const showButton = key => user && (!!user.is_super || includes(buttonsByType[user.type], key))

export default createSelector(
  selectPathname,
  (pathname) => {

    const left = [
      {
        key: "dashboard",
        id: "dashboard",
        className: "dashboard",
        title: 'Dashboard',
        href: getTeamUrl('dashboard'),
        selected: pathname && pathname.startsWith(getTeamUrl('dashboard')),
        show: showButton('dashboard'),
      },
      {
        key: "projects",
        title: 'Projects',
        href: getTeamUrl('projects'),
        selected: pathname && pathname.startsWith(getTeamUrl('projects')),
        show: showButton('projects'),
      },
      {
        key: "estimates",
        title: 'Estimates',
        href: getTeamUrl('estimates'),
        selected: pathname && pathname.startsWith(getTeamUrl('estimates')),
        show: showButton('estimates'),
      },
      {
        key: "orders",
        title: 'Orders',
        href: getTeamUrl('orders'),
        selected: pathname && pathname.startsWith(getTeamUrl('orders')),
        show: showButton('orders'),
      },
      {
        key: "valuations",
        title: 'Valuations',
        href: getTeamUrl('valuations'),
        selected: pathname && pathname.startsWith(getTeamUrl('valuations')),
        show: showButton('valuations'),
      },
      {
        key: "invoices",
        title: 'Invoices',
        href: getTeamUrl('invoices'),
        selected: pathname && pathname.startsWith(getTeamUrl('invoices')),
        show: showButton('invoices'),
      },
      {
        key: "retention_payments",
        title: 'Retentions',
        href: getTeamUrl('retention_payments'),
        selected: pathname && pathname.startsWith(getTeamUrl('retention_payments')),
        show: showButton('retention_payments'),
      },
      {
        key: "rams_records",
        title: 'RAMS',
        href: getTeamUrl('rams_records'),
        selected: pathname && pathname.startsWith(getTeamUrl('rams_records')),
        show: showButton('rams_records'),
      },
      {
        key: "clients",
        title: 'Clients',
        href: getTeamUrl('clients'),
        selected: pathname && pathname.startsWith(getTeamUrl('clients')),
        show: showButton('clients'),
      },
      {
        key: "subcontractors",
        title: 'Subcontractors',
        href: getTeamUrl('subcontractors'),
        selected: pathname && (
          pathname.startsWith(getTeamUrl('subcontractors')) ||
          pathname.startsWith(getTeamUrl('sub_projects')) ||
          pathname.startsWith(getTeamUrl('sub_orders')) ||
          pathname.startsWith(getTeamUrl('sub_valuations')) ||
          pathname.startsWith(getTeamUrl('sub_invoices'))
        ),
        show: showButton('subcontractors'),
      },
      {
        key: "planner",
        title: 'Planner',
        href: getTeamUrl('planner'),
        selected: pathname && pathname.startsWith(getTeamUrl('planner')),
        show: showButton('planner'),
      },
      // {
      //   key: "timesheet_posting",
      //   title: 'Time Entry',
      //   href: getTeamUrl('timesheet_posting'),
      //   selected: pathname && pathname.startsWith(getTeamUrl('timesheet_posting')),
      //   show: user && includes(buttonsByType[user.type], 'timesheet_posting'),
      // },
      {
        key: "timesheets",
        title: 'Timesheets',
        href: getTeamUrl('timesheets'),
        selected: pathname && pathname.startsWith(getTeamUrl('timesheets')),
        show: showButton('timesheets'),
      },
      {
        key: "profit_and_loss",
        title: 'Profit & Loss',
        href: getTeamUrl('profit_and_loss'),
        selected: pathname && pathname.startsWith(getTeamUrl('profit_and_loss')),
        show: showButton('profit_and_loss'),
      },
      {
        key: "insights",
        title: 'Insights',
        href: getTeamUrl('insights'),
        selected: pathname && pathname.startsWith(getTeamUrl('insights')),
        show: showButton('insights'),
      },
    ].filter(button => button.show)

    let usertype = "";
    if (user !== undefined)
	  usertype = user.type;

    const teams = getTeams();
    const right = [
        {
            icon: lifebuoy,
            key: "knowledgebase",
            title: 'Help',
            target: '_new',
            hreffull: "https://leadpm.cloud/help/",
        },
        {
            key: "logout",
            title: "logout-menu-" + usertype,
            icon: userIcon,
            dropdown: (
                <Menu selectedKeys={[get(currentTeam, 'slug')]} style={{minWidth: 160}} show={true}>
                    {showButton('settings') && (<Menu.Item key='settings'>
                        <Link to={getTeamUrl('settings')}>Settings</Link>
                    </Menu.Item>)}
                    {showButton('emails') && (<Menu.Item key='emails'>
                        <Link to={getTeamUrl('emails')}>Emails</Link>
                    </Menu.Item>)}
                    {showButton('users') && (
                        <Menu.Item key='users' selected={pathname && pathname.startsWith(getTeamUrl('users'))}>
                            <Link to={getTeamUrl('users')}>Users</Link>
                        </Menu.Item>)}
                    {!!teams.length && (
                        <React.Fragment>
                            <Menu.ItemGroup title='Teams'>
                                {teams.map(team => (
                                    <Menu.Item
                                        key={team.slug}
                                        onClick={() => goToTeam(team)}
                                    >
                                        {team.name}
                                    </Menu.Item>
                                ))}
                            </Menu.ItemGroup>
                        </React.Fragment>
                    )}
                    <React.Fragment>
                        <Menu.Divider/>
                    </React.Fragment>
                    <Menu.Item key='logout'>
                        <Link to='/logout'>Logout</Link>
                    </Menu.Item>
                </Menu>
            ),
        },
        {
            key: "mobile",
            title: "mobile-menu-" + usertype,
            icon: menuIcon,
            dropdown: (
                <Menu selectedKeys={[pathname]} style={{minWidth: 160}} show={true}>
                    {!!left.length && (
                        <React.Fragment>
                            {left.map(submenu => (
                                <Menu.Item
                                    key={submenu.key}
                                >
                                    <Link to={submenu.href}>{submenu.title}</Link>
                                </Menu.Item>
                            ))}
                        </React.Fragment>
                    )}
                </Menu>
            ),
        }
    ];

    return {
      left,
      right,
    };
  }
)



