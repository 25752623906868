exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2HfLiPQyQqP4gqcnmiN2jM {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n._2wFmMpVs901ZYs3F4FImNb {\n  height: 45px;\n  background-color: #F5F5F5;\n  border-bottom: 1px solid #DDD;\n  padding-left: 5px;\n  padding-right: 5px;\n  display: flex;\n  align-items: center;\n}\n\n._3-MFz0k-7-Jw0WBNIdQX1i {\n  height: 100%;\n  border-right: 1px solid #DDD;\n  padding: 10px;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 25px;\n  color: #999;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n._3-MFz0k-7-Jw0WBNIdQX1i:last-child {\n  border-right: none;\n}\n\n._3TbQUxfYSRhVfo0gffKSHc {\n  flex: 1;\n}\n\n.fgtI8heWvzXg9IKvimvuY {\n  width: 180px;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Modules/FileManager/Components/List/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,QAAQ;CACT;;AAED;EACE,aAAa;EACb,0BAA0B;EAC1B,8BAA8B;EAC9B,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,aAAa;EACb,6BAA6B;EAC7B,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,oBAAoB;EACpB,iBAAiB;EACjB,wBAAwB;CACzB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,aAAa;CACd","file":"styles.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.headings {\n  height: 45px;\n  background-color: #F5F5F5;\n  border-bottom: 1px solid #DDD;\n  padding-left: 5px;\n  padding-right: 5px;\n  display: flex;\n  align-items: center;\n}\n\n.col {\n  height: 100%;\n  border-right: 1px solid #DDD;\n  padding: 10px;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 25px;\n  color: #999;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.col:last-child {\n  border-right: none;\n}\n\n.nameCol {\n  flex: 1;\n}\n\n.timestampCol {\n  width: 180px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2HfLiPQyQqP4gqcnmiN2jM",
	"headings": "_2wFmMpVs901ZYs3F4FImNb",
	"col": "_3-MFz0k-7-Jw0WBNIdQX1i",
	"nameCol": "_3TbQUxfYSRhVfo0gffKSHc",
	"timestampCol": "fgtI8heWvzXg9IKvimvuY"
};