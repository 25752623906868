exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._16JgaGlnAvO6n-KigYuXdQ {\n  color: #C60302;\n  font-weight: bold;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/ProjectView/Components/RetentionPayments/Component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;CACnB","file":"Component.css","sourcesContent":[".overdue {\n  color: #C60302;\n  font-weight: bold;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"overdue": "_16JgaGlnAvO6n-KigYuXdQ"
};