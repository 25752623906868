
import React from 'react'
import Screen from '../../Components/Screen'

import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Table from './Components/Table'

import Filters from './Components/Filters'
import RamsSigning from '../../Modals/RamsSigning'
import User from "../../Modals/User";

type Props = {
  ramsSignings: [],
  fetching: boolean,
  searchTerm: string,
  filters: {},
  pagination: {},
  ramsSigningModal: boolean,
  componentDidMount: () => void,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onChangeFilter: () => void,
  onChangeTable: () => void,
  onClickRow: () => void,
  onClickExport: () => void,
  onClickNewRamsSigning: () => void,
  onCloseRamsSigningModal: () => void,
  didCreateRamsSigning: () => void,
  didUpdateRamsSigning: () => void,
  didDeleteRamsSigning: () => void,
  didDeactivateRamsSigning: () => void,
  didReactivateRamsSigning: () => void,
}

export default class extends React.PureComponent<Props> {


  componentDidMount = () => this.props.componentDidMount()

  render() {

    const {
      ramsSignings,
      fetching,
      searchTerm,
      filters,
      pagination,
      ramsSigningModal,
      onChangeSearch,
      onSubmitSearch,
      onChangeFilter,
      onChangeTable,
      onClickRow,
      onClickExport,
      onClickNewRamsSigning,
      onCloseRamsSigningModal,
      didCreateRamsSigning,
      didUpdateRamsSigning,
      didDeleteRamsSigning,
      didDeactivateRamsSigning,
      didReactivateRamsSigning,
    } = this.props

    return (

      <Screen>

        <Breadcrumbs />

        <Header
          searchTerm={searchTerm}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
          onClickExport={onClickExport}
          onClickNewRamsSigning={onClickNewRamsSigning}
        />

        <Filters
             filters={filters}
             onChangeFilter={onChangeFilter}
        />

        <Table
          ramsSignings={ramsSignings}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
          onClickRow={onClickRow}
        />

          {ramsSigningModal.show && (
              <RamsSigning
                  id={ramsSigningModal.id}
                  onClose={onCloseRamsSigningModal}
                  didCreate={didCreateRamsSigning}
                  didUpdate={didUpdateRamsSigning}
                  didDelete={didDeleteRamsSigning}
                  didDeactivate={didDeactivateRamsSigning}
                  didReactivate={didReactivateRamsSigning}
              />
          )}

      </Screen>

    )

  }

}
