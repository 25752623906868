exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._25Ep37UUIC1UPMB96ab_3W {\n  display: flex;\n  justify-content: flex-end;\n}\n\n._21h7yH8HErbVHZzvxkjOYb {\n  margin-right: auto;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Modals/RamsRecord/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;CAC3B;;AAED;EACE,mBAAmB;CACpB","file":"Component.css","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.deleteButton {\n  margin-right: auto;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "_25Ep37UUIC1UPMB96ab_3W",
	"deleteButton": "_21h7yH8HErbVHZzvxkjOYb"
};