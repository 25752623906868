import { connect } from 'react-redux'

import Emails from './Emails'

import componentDidMount from './Handlers/componentDidMount'
import onChangeSearch from './Handlers/onChangeSearch'
import onSubmitSearch from './Handlers/onSubmitSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'
import onClickNewEmail from './Handlers/onClickNewEmail'
import onCloseEmailModal from './Handlers/onCloseEmailModal'
import didCreateEmail from './Handlers/didCreateEmail'

import didUpdateEmail from './Handlers/didUpdateEmail'
import didDeleteEmail from './Handlers/didDeleteEmail'
//import didDeactivateEmail from './Handlers/didDeactivateEmail'
//import didReactivateEmail from './Handlers/didReactivateEmail'

const mapStateToProps = (state) => ({
  emails: state.screens.Emails.emails,
  fetching: state.screens.Emails.fetching,
  searchTerm: state.screens.Emails.searchTerm,
  filters: state.screens.Emails.filters,
  pagination: state.screens.Emails.pagination,
  emailModal: state.screens.Emails.emailModal,
  addtionalFilter: {}
})

const actionCreators = {
  componentDidMount,
  onChangeTable,
  onCloseEmailModal,
  didCreateEmail,
  onClickNewEmail,
  onChangeSearch,
  onSubmitSearch,
  onClickRow,
  onChangeFilter,
  didUpdateEmail,
  didDeleteEmail
}

export default connect(mapStateToProps, actionCreators)(Emails)

