exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n\n._25u9HiMNDBDLhkI5Q5NYGL[src=\"\"]\n{\n    display: none;\n}\n\n._2wCd_Z4rTfawBB_uNH6Tk-\n{\n    cursor: pointer;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Modals/Setting/Components/LogoStyle.css"],"names":[],"mappings":";;AAEA;;IAEI,cAAc;CACjB;;AAED;;IAEI,gBAAgB;CACnB","file":"LogoStyle.css","sourcesContent":["\n\n.settingLogo[src=\"\"]\n{\n    display: none;\n}\n\n.uploaderPointer\n{\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"settingLogo": "_25u9HiMNDBDLhkI5Q5NYGL",
	"uploaderPointer": "_2wCd_Z4rTfawBB_uNH6Tk-"
};