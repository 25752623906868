import { createAction, handleActions } from 'redux-actions'

const setEmails = createAction('SCREENS/EMAILS/SET_EMAILS')

const defaultState = []

const reducer = handleActions(
  {
    [setEmails]: (state, action) => action.payload,
  },
  defaultState,
)
// || null
export { setEmails, reducer }
