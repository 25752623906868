import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import Fields from './Components/Fields'
import Footer from './Components/Footer'

export default class RamsRecord extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      onClose,
    } = this.props

    return (

      <Modal
        visible
        title="RAMS"
        onCancel={onClose}
        footer={<Footer />}
        width={900}
      >

        <Fields />

      </Modal>

    )
  }
}
