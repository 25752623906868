exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n\n._1v2eBptlUXs9c9UztTjg-e, ._61LGxWVGcWMaZZmQSIOF5 {\n    /*\n    height: 100vh;\n    width: 100vw;\n    */\n    height: 100%;\n    width: 100%;\n    margin: 0;\n    top: 0;\n}\n\n._1v2eBptlUXs9c9UztTjg-e\n{\n    background: #000!important;\n}\n\n._1v2eBptlUXs9c9UztTjg-e\n{\n    top: 8px !important;\n}\n\n._3F0eena6OyG7YccW2UrwbY {\n    height: calc(100vh - 110px);\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Modals/RamsSigning/styles.css"],"names":[],"mappings":";;AAEA;IACI;;;MAGE;IACF,aAAa;IACb,YAAY;IACZ,UAAU;IACV,OAAO;CACV;;AAED;;IAEI,2BAA2B;CAC9B;;AAED;;IAEI,oBAAoB;CACvB;;AAED;IACI,4BAA4B;CAC/B","file":"styles.css","sourcesContent":["\n\n.ant-modal, .ant-modal-content {\n    /*\n    height: 100vh;\n    width: 100vw;\n    */\n    height: 100%;\n    width: 100%;\n    margin: 0;\n    top: 0;\n}\n\n.ant-modal\n{\n    background: #000!important;\n}\n\n.ant-modal\n{\n    top: 8px !important;\n}\n\n.ant-modal-body {\n    height: calc(100vh - 110px);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"ant-modal": "_1v2eBptlUXs9c9UztTjg-e",
	"antModal": "_1v2eBptlUXs9c9UztTjg-e",
	"ant-modal-content": "_61LGxWVGcWMaZZmQSIOF5",
	"antModalContent": "_61LGxWVGcWMaZZmQSIOF5",
	"ant-modal-body": "_3F0eena6OyG7YccW2UrwbY",
	"antModalBody": "_3F0eena6OyG7YccW2UrwbY"
};