import React from 'react'
import { Breadcrumb, Divider } from 'antd'
import { books as booksIcon } from 'react-icons-kit/icomoon/books';


const { Item } = Breadcrumb;


export default () => (

  <React.Fragment>

    <Breadcrumb separator='»'>

      <Item>
        Home
      </Item>

      <Item>
        Rams Signings
      </Item>

    </Breadcrumb>

    <Divider />

  </React.Fragment>

)
