import React from 'react'
import { Modal, Dropdown, Menu, Button } from 'antd'
import { DeleteOutlined, FrownOutlined, SafetyOutlined, SmileOutlined } from '@ant-design/icons'
import Form from './Form'

type Props = {
    id: ?any,
    to: string,
    cc: string,
    bcc: string,
    subject: string,
    body: string,
    status: string,
    opened: boolean,
    bounced: boolean,
    sent_at: string,
    create_at: string,
    deleted_at: string,
    submitting: boolean,
    email_attachments: ?any,
    logo: string,
    resetPasswordModal: {},
    componentWillMount: (props: any) => void,
    onClickSubmit: () => void,
    onClickCancel: () => void,
    onClickDelete: () => void,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props)

  getOptionsMenu = () => {
    const {
      onClickDelete,
    } = this.props
    return (
      <Menu
        onClick={({ key }) => {
          if (key === 'delete') {
            onClickDelete()
          }
        }}
      >
        <Menu.Item key='delete'><DeleteOutlined />{` Delete`}</Menu.Item>
      </Menu>
    )
  }

  render() {

    const {
        id,
        team_id,
        owner_id,
        owner_type,
        project_id,
        subject,
        to,
        cc,
        bcc,
        body,
        html,
        sent_by_user_id,
        status,
        email_attachments,
        opened,
        bounced,
        sent_at,
        created_at,
        updated_at,
        submitting,
        logo,
        onUpload,
        onClickSubmit,
        onClickCancel,
    } = this.props

    const footer = []

    /*if (id) {
      footer.push([
        <Dropdown key='options' overlay={this.getOptionsMenu()}>
          <Button style={{ float: 'left' }} loading={submitting}>
            Options
          </Button>
        </Dropdown>,
      ])
    }*/

    footer.push([
      <Button key='cancel' disabled={submitting} onClick={onClickCancel}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        Save
      </Button>,
    ])

    return (

      <Modal
        title={'Edit Email: ' + subject}
        footer={footer}
        onCancel={onClickCancel}
        visible
      >

        <Form
          id={id}
          team_id={team_id}
          owner_id={owner_id}
          owner_type={owner_type}
          project_id={project_id}
          subject={subject}
          to={to}
          cc={cc}
          bcc={bcc}
          body={body}
          html={html}
          sent_by_user_id={sent_by_user_id}
          status={status}
          email_attachments={email_attachments}
          opened={opened}
          bounced={bounced}
          sent_at={sent_at}
          created_at={created_at}
          updated_at={updated_at}
          submitting={submitting}
          logo={logo}
          onUpload={onUpload}
        />

      </Modal>

    )

  }

}
