
import React from 'react'
import Screen from '../../Components/Screen'

import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Table from './Components/Table'

import Filters from './Components/Filters'
import Email from '../../Modals/Email'
import User from "../../Modals/User";
import { store } from 'Evolve/store'

type Props = {
  emails: [],
    componentView: boolean,
    additionalFilter: {},
  fetching: boolean,
  searchTerm: string,
  filters: {},
  pagination: {},
  emailModal: boolean,
  componentDidMount: () => void,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onChangeFilter: () => void,
  onChangeTable: () => void,
  onClickRow: () => void,
  onClickExport: () => void,
  onClickNewEmail: () => void,
  onCloseEmailModal: () => void,
  didCreateEmail: () => void,
  didUpdateEmail: () => void,
  didDeleteEmail: () => void,
  didDeactivateEmail: () => void,
  didReactivateEmail: () => void,
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount()

  render() {

    const {
      componentView,
      additionalFilter,
      emails,
      fetching,
      searchTerm,
      filters,
      pagination,
      emailModal,
      onChangeSearch,
      onSubmitSearch,
      onChangeFilter,
      onChangeTable,
      onClickRow,
      onClickExport,
      onClickNewEmail,
      onCloseEmailModal,
      didCreateEmail,
      didUpdateEmail,
      didDeleteEmail,
      didDeactivateEmail,
      didReactivateEmail,
    } = this.props

    store.getState().screens.Emails.additionalFilter = {};
    if (componentView)
    {
        store.getState().screens.Emails.additionalFilter = additionalFilter;

        return (
            <div>
                <Table
                    componentView={componentView}
                    additionalFilter={additionalFilter}
                    emails={emails}
                    fetching={fetching}
                    pagination={pagination}
                    onChangeTable={onChangeTable}
                    onClickRow={onClickRow}
                />

                {emailModal.show && (
                    <Email
                        id={emailModal.id}
                        onClose={onCloseEmailModal}
                        didCreate={didCreateEmail}
                        didUpdate={didUpdateEmail}
                        didDelete={didDeleteEmail}
                        didDeactivate={didDeactivateEmail}
                        didReactivate={didReactivateEmail}
                    />
                )}
            </div>
        )
    }else{

        return (

            <Screen>

                <Breadcrumbs />

                <Header
                    searchTerm={searchTerm}
                    onChangeSearch={onChangeSearch}
                    onSubmitSearch={onSubmitSearch}
                    onClickExport={onClickExport}
                    onClickNewEmail={onClickNewEmail}
                />

                <Filters
                    filters={filters}
                    onChangeFilter={onChangeFilter}
                />

                <Table
                    emails={emails}
                    fetching={fetching}
                    pagination={pagination}
                    onChangeTable={onChangeTable}
                    onClickRow={onClickRow}
                />

                {emailModal.show && (
                    <Email
                        id={emailModal.id}
                        onClose={onCloseEmailModal}
                        didCreate={didCreateEmail}
                        didUpdate={didUpdateEmail}
                        didDelete={didDeleteEmail}
                        didDeactivate={didDeactivateEmail}
                        didReactivate={didReactivateEmail}
                    />

                )}

            </Screen>

        )

    }


  }

}
