import axios from './axios'

export const createRamsSigning = ({ data }) => axios.post('ramssigning', data)
export const deleteRamsSigning = ({ id }) => axios.delete(`ramssigning/${id}`)
export const findRamsSigning = ({ params, id } = {}) => axios.get(`ramssigning/${id}`, { params })
export const getRamsSignings = ({ params } = {}) => axios.get('ramssigning', { params })
export const updateRamsSigning = ({ id, data }) => axios.put(`ramssigning/${id}`, data)
export const resetRamsSigningPassword = ({ id, data }) => axios.put(`ramssigning${id}/reset_password`, data)
export const deactivateRamsSigning = ({ id, data }) => axios.put(`ramssigning/${id}/deactivate`, data)
export const reactivateRamsSigning = ({ id, data }) => axios.put(`ramssigning/${id}/reactivate`, data)
export const uploadRamsSigningsFile = ({ id, data, config } = {}) => axios.post(`ramssigning/${id}/files/upload_file`, data, config);


