exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1NwAgEfHVEaBFy9cqmYITY {\n  width: 100%;\n  height: 70px;\n  border-top: 1px solid #DDD;\n  background-color: white;\n  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.1);\n  position: fixed;\n  bottom: 0px;\n  left: 0px;\n  z-index: 1;\n  padding-left: 30px;\n  padding-right: 310px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n._2JOeDAd3ij19h4voAgqu2U {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n._2l_wd8473J4Wxc7VXi-5sn {\n  display: flex;\n}\n\n._1jftU_VLSw7EKUF2M4erwc {\n  font-size: 14px;\n  font-weight: 600;\n  margin-left: 30px;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/EstimateView/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,wBAAwB;EACxB,4CAA4C;EAC5C,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,qBAAqB;EACrB,cAAc;EACd,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;EACd,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,cAAc;CACf;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;CACnB","file":"Component.css","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 70px;\n  border-top: 1px solid #DDD;\n  background-color: white;\n  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.1);\n  position: fixed;\n  bottom: 0px;\n  left: 0px;\n  z-index: 1;\n  padding-left: 30px;\n  padding-right: 310px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.buttons {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.totals {\n  display: flex;\n}\n\n.total {\n  font-size: 14px;\n  font-weight: 600;\n  margin-left: 30px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1NwAgEfHVEaBFy9cqmYITY",
	"buttons": "_2JOeDAd3ij19h4voAgqu2U",
	"totals": "_2l_wd8473J4Wxc7VXi-5sn",
	"total": "_1jftU_VLSw7EKUF2M4erwc"
};