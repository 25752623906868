import { createAction, handleActions } from 'redux-actions'

const setRamsSignings = createAction('SCREENS/RAMSSIGNINGS/SET_RAMSSIGNINGS')

const defaultState = []

const reducer = handleActions(
  {
    [setRamsSignings]: (state, action) => action.payload,
  },
  defaultState,
)

export { setRamsSignings, reducer }
