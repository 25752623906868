exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3lUXTixIn6ol3YeEf3eIcu {\n  width: 100%;\n  height: 70px;\n  border-top: 1px solid #DDD;\n  background-color: white;\n  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.1);\n  position: fixed;\n  bottom: 0px;\n  left: 0px;\n  z-index: 1;\n  padding-left: 30px;\n  padding-right: 310px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n._3gA9t216_wxqJD5iNBvo5k {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n._2oXuDZiUeJMWdcNB633_Ew,\n._3wiNyp6bK7yK5ZV2uBUDGw {\n  margin-left: auto;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/ProjectView/Components/TargetCosts/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,wBAAwB;EACxB,4CAA4C;EAC5C,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,qBAAqB;EACrB,cAAc;EACd,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;EACd,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;;EAEE,kBAAkB;CACnB","file":"Component.css","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 70px;\n  border-top: 1px solid #DDD;\n  background-color: white;\n  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.1);\n  position: fixed;\n  bottom: 0px;\n  left: 0px;\n  z-index: 1;\n  padding-left: 30px;\n  padding-right: 310px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.buttons {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.exportButton,\n.setButton {\n  margin-left: auto;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3lUXTixIn6ol3YeEf3eIcu",
	"buttons": "_3gA9t216_wxqJD5iNBvo5k",
	"exportButton": "_2oXuDZiUeJMWdcNB633_Ew",
	"setButton": "_3wiNyp6bK7yK5ZV2uBUDGw"
};