import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Upload, Button } from 'antd';
import { InputField, DatePickerField, TextAreaField, SelectField } from 'FormFields'

import paint from './Misc/paints'
import accessEquipment from './Misc/accessEquipment'

const labelColSpan = 6
const wrapperColSpan = 16

export default class _Fields_ extends React.PureComponent {

  static propTypes = {
    serviceTypes: PropTypes.array.isRequired,
    userSettings: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
    submitting: PropTypes.bool,
    onBeforeUpload: PropTypes.func.isRequired,
    onChangeFiles: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: null,
  }

  render () {

    const {
      serviceTypes,
      userSettings,
      files,
      submitting,
      onBeforeUpload,
      onChangeFiles,
    } = this.props

    let ppe = userSettings.ppe;
    let training = userSettings.training;

    let access = accessEquipment;
    if (userSettings.access.length > 0)
        access = userSettings.access;


    let paints = paint;
    if (userSettings.paints.length > 0)
        paints = userSettings.paints;


    return (
    <div>
        <div style={{ display: 'flex' }}>

            <div style={{ flex: 1 }}>

                <Form.Item
                  label="Files"
                  labelCol={{ span: labelColSpan }}
                  wrapperCol={{ span: wrapperColSpan }}
                >

                  <Upload
                    mulitple
                    fileList={files}
                    beforeUpload={onBeforeUpload}
                    onChange={onChangeFiles}
                    disabled={submitting}
                  >

                    <Button disabled={submitting}>
                      <UploadOutlined /> Select Files
                    </Button>

                  </Upload>

                </Form.Item>

                <br />

                <Field
                  name="valid_from"
                  label="Valid From"
                  disabled={submitting}
                  component={DatePickerField}
                  labelCol={{ span: labelColSpan }}
                  wrapperCol={{ span: wrapperColSpan / 2 }}
                  required
                />

                <Field
                  name="valid_to"
                  label="Valid To"
                  disabled={submitting}
                  component={DatePickerField}
                  labelCol={{ span: labelColSpan }}
                  wrapperCol={{ span: wrapperColSpan / 2 }}
                  required
                />

                <br />






            </div>

            <div style={{ flex: 1 }}>

                <Field
                    name="services"
                    label="Services"
                    disabled={submitting}
                    component={SelectField}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    required
                    mode="multiple"
                    options={serviceTypes.filter(serviceType => serviceType.key !== 'O').map(serviceType => ({
                        value: serviceType.key,
                        label: serviceType.title,
                    }))}
                />

                <Field
                    name="access_equipment"
                    label="Access Equipment"
                    disabled={submitting}
                    component={SelectField}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    mode="tags"
                    options={access.sort().map(item => ({
                        value: item.key,
                        label: item.value,
                    }))}
                    tokenSeparators={[',']}
                />

                <Field
                    name="paints"
                    label="Paints"
                    disabled={submitting}
                    component={SelectField}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    mode="tags"
                    options={paints.sort().map(item => ({
                        value: item.name,
                        label: item.name,
                    }))}
                    tokenSeparators={[',']}
                />

                <Field
                    name="ppe"
                    label="PPE"
                    disabled={submitting}
                    component={SelectField}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    mode="multiple"
                    options={ppe.map(ppe => ({
                        value: ppe.key,
                        label: ppe.value,
                    }))}
                />

                <Field
                    name="training"
                    label="Training"
                    disabled={submitting}
                    component={SelectField}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    mode="multiple"
                    options={training.map(training => ({
                        value: training.key,
                        label: training.value,
                    }))}
                />

                <Field
                    name="spray_timing"
                    label="Spray timing"
                    disabled={submitting}
                    component={SelectField}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    mode="single"
                    options={[{"value":"In hours","label":"In hours"},{"value":"Out of hours","label":"Out of hours"},{"value":"In/Out of hours","label":"In/Out of hours"}]}
                />

            </div>

          </div>

          <div style={{ display: 'flex' }}>

              <div style={{ flex: 1 }}>
                  <Field
                      name="notes"
                      label="Notes"
                      disabled={submitting}
                      component={TextAreaField}
                      labelCol={{ span: labelColSpan }}
                      wrapperCol={{ span: wrapperColSpan }}
                      style={{ height: 80 }}
                  />
                  <Field
                      name="scope"
                      label="Scope"
                      disabled={submitting}
                      component={TextAreaField}
                      labelCol={{ span: labelColSpan }}
                      wrapperCol={{ span: wrapperColSpan }}
                      style={{ height: 80 }}
                  />

                  <Field
                      name="site_access"
                      label="Site access"
                      disabled={submitting}
                      component={TextAreaField}
                      labelCol={{ span: labelColSpan }}
                      wrapperCol={{ span: wrapperColSpan }}
                      style={{ height: 80 }}
                  />
              </div>

              <div style={{ flex: 1 }}>

                  <Field
                      name="nearest_ae_name"
                      label="A&E Name"
                      disabled={submitting}
                      component={InputField}
                      labelCol={{ span: labelColSpan }}
                      wrapperCol={{ span: wrapperColSpan }}
                  />

                  <Field
                      name="nearest_ae_address"
                      label="A&E Address"
                      disabled={submitting}
                      component={TextAreaField}
                      labelCol={{ span: labelColSpan }}
                      wrapperCol={{ span: wrapperColSpan }}
                      style={{ height: 80 }}
                  />

              </div>
          </div>

        </div>
    );
  }
}
