import { getClientContacts } from '../../../HttpRequests/clientContacts';
import { loadClientContacts } from '../State/clientContacts';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch) => {

  try {
//'filter[decides_estimates]': 1,
    const params = {
      'csort': 'full_name',
      pagination: 0,
    };

    params.include = [
      'client',
    ].join();

    const response = await getClientContacts({ params });

    dispatch(loadClientContacts(response.data.data));

  } catch (error) {

    handleException(error);

  }
}
