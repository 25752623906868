
import { getRamsSignings } from '../../../HttpRequests/ramsSignings'
import { setRamsSignings } from '../State/ramsSignings'
import { setFetching } from '../State/fetching'
import { setPagination } from '../State/pagination'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { filters, sorting, pagination } = getState().screens.RamsSigning

  const params = {}

  if (filters.status !== 'All') {
//    params['filter[' + filters.status + ']'] = 1;
  }

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  params.include = [
      'project',
      'project.clientSite',
      'project.clientSite.client',
      'subcontractor',
      'ramsRecord'
  ].join();

  dispatch(setFetching(true))

  try {

    const response = await getRamsSignings({ params })
    dispatch(setRamsSignings(response.data.rows))

    dispatch(setPagination({
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
      total: response.data.pagination.total,
    }))

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error)

  }

}
