import { getProps } from '../props'
import create from '../Services/create'
import update from '../Services/update'

export default async (values, dispatch) => {

  const { onClose, didCreate, didUpdate } = getProps()

  if (!values.id) {

    const user = await dispatch(create(values))

    onClose()

    if (didCreate) didCreate(user)

  } else {

    const user = await dispatch(update(values))

    onClose()

    if (didUpdate) didUpdate(user)

  }

}
