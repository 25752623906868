import { get } from 'lodash'

const isSubContractor = () => {

    const type = get(window, 'state.user.type')

    return type === 'Subcontractor'

}

export default isSubContractor
