import moment from 'moment';
import { getUserSettings } from '../../HttpRequests/userSettings';
import { loadUserSettings } from '../State/userSettings';

let lastGetTimestamp = null;

const cacheTTL = 60000;

export default ({ force } = {}) => async (dispatch) => {

    try {

        if (!force && lastGetTimestamp) {

            const now = Number(moment().format('x'));

            if (now < (lastGetTimestamp + cacheTTL)) return;
        }

        lastGetTimestamp = Number(moment().format('x'));

        const response = await getUserSettings();

        dispatch(loadUserSettings(response.data.data));

    } catch (error) {

        throw error;

    }
}
