exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3PreK0DzjzV9W9v1LDXSDm {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._1G69avX5X14QLD5aiOsoLd {\n  flex: 1;\n  display: flex;\n}\n\n._3uvSe6kK0nyWQBejk4max0 {\n  display: flex;\n}\n\n._330p9SuX6q5tlpOEpeMhuf {\n  width: 280px;\n}\n\n.z0u297lOJYMMtEup9kMl_ {\n  margin-bottom: 12px;\n}\n\n.z0u297lOJYMMtEup9kMl_:last-child {\n  margin-bottom: 4px;\n}\n\n._22Qs2LBuXwHVgNb-dHQIaF {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/Subbies/Components/SubOrders/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;EACE,cAAc;CACf;;AAED;EACE,aAAa;CACd;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".controls {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.right {\n  display: flex;\n}\n\n.filters {\n  width: 280px;\n}\n\n.box {\n  margin-bottom: 12px;\n}\n\n.box:last-child {\n  margin-bottom: 4px;\n}\n\n.label {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"controls": "_3PreK0DzjzV9W9v1LDXSDm",
	"left": "_1G69avX5X14QLD5aiOsoLd",
	"right": "_3uvSe6kK0nyWQBejk4max0",
	"filters": "_330p9SuX6q5tlpOEpeMhuf",
	"box": "z0u297lOJYMMtEup9kMl_",
	"label": "_22Qs2LBuXwHVgNb-dHQIaF"
};