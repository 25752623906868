
import { connect } from 'react-redux'
import { formValueSelector, isSubmitting } from 'redux-form'
import Email from './Email'

import { FORM_NAME } from './Form'

import componentWillMount from './Handlers/componentWillMount'
import onClickSubmit from './Handlers/onClickSubmit'
import onClickCancel from './Handlers/onClickCancel'
import onClickDelete from './Handlers/onClickDelete'
import onUpload from './Handlers/onUpload'

const mapStateToProps = (state) => ({

    to: formValueSelector(FORM_NAME)(state, 'to'),
    cc: formValueSelector(FORM_NAME)(state, 'cc'),
    bcc: formValueSelector(FORM_NAME)(state, 'bcc'),
    subject: formValueSelector(FORM_NAME)(state, 'subject'),
    body: formValueSelector(FORM_NAME)(state, 'body'),
    opened: formValueSelector(FORM_NAME)(state, 'opened'),
    bounced: formValueSelector(FORM_NAME)(state, 'bounced'),
    status: formValueSelector(FORM_NAME)(state, 'status'),
    email_attachments: formValueSelector(FORM_NAME)(state, 'email_attachments'),

    submitting: isSubmitting(FORM_NAME)(state),
    logo: state.modals.Email.logo,

})

const actionCreators = {
    componentWillMount,
    onClickSubmit,
    onClickCancel,
    onClickDelete,
    onUpload,
}


export default connect(mapStateToProps, actionCreators)(Email)
