// @flow

import * as React from 'react';
import { Tabs, Spin } from 'antd';
import { isAdmin } from 'Common';
import Screen from '../../Components/Screen';

import Initializing from './Components/Initializing';
import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import FileManager from './Components/FileManager';
import Estimates from './Components/Estimates';
import Orders from './Components/Orders'
import TargetCosts from './Components/TargetCosts'
import Valuations from './Components/Valuations';
import Invoices from './Components/Invoices';
import RetentionPayments from './Components/RetentionPayments';
import RamsRecords from './Components/RamsRecords';
import Activity from './Components/Activity';

import ProjectModal from '../../Modals/Project';
import EstimateModal from '../../Modals/Estimate';
import OrderModal from '../../Modals/Order';
import ValuationDateModal from '../../Modals/ValuationDate';
import ValuationModal from '../../Modals/Valuation';
import UploadModal from '../../Modals/Upload';
import DirectoryModal from '../../Modals/Directory';
import JobSheetModal from '../../Modals/JobSheet';
import ProjectCompleteModal from '../../Modals/ProjectComplete';
import MoveFilesModal from '../../Modals/MoveFiles';
import ClientSiteModal from '../../Modals/ClientSite';
import ClientContactModal from '../../Modals/ClientContact';
import ViewEmailModal from '../../Modals/ViewEmail';
import RetentionPaymentModal from '../../Modals/RetentionPayment'
import RamsRecordModal from '../../Modals/RamsRecord'
import EmailProjectInformationModal from '../../Modals/EmailProjectInformation'
import EmailModal from '../../Modals/Email'
import Emails from '../../Screens/Emails'

import type { ScreenProps } from './types';

export default class extends React.PureComponent<ScreenProps> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentDidUpdate = () => this.props.componentDidUpdate(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {

      project,
      fetching,
      rootDirectories,
      currentDirectoryPath,
      selectedItemPath,
      checkedItemPaths,
      listItems,
      activeTab,
      selectedEmailId,

      estimateStatuses,
      estimateTenderStatuses,
      estimateLatestActions,
      users,

      showProjectModal,
      showEstimateModal,
      showOrderModal,
      showValuationModal,
      showUploadModal,
      showDirectoryModal,
      showJobSheetModal,
      showClientSiteModal,
      showClientContactModal,
      showEmailModal,
      showViewEmailModal,

      fetchingDirectory,
      fetchingRootDirectories,

      didUpdateProject,
      didDeleteProject,
      didCreateEstimate,
      didCreateOrder,
      didCreateValuation,
      didUpdateClientSite,
      didUpdateClientContact,

      onClickActionMenuItem,
      onCloseProjectModal,
      onCloseEstimateModal,
      onCloseOrderModal,
      onCloseValuationModal,
      onCloseClientSiteModal,
      onCloseClientContactModal,
      onCloseViewEmailModal,
      showProjectCompleteModal,
      showMoveFilesModal,

      onSelectRootDirectory,
      onSelectItem,
      onDoubleClickItem,
      onToggleItemCheckbox,

      onClickNewEstimate,
      onClickNewOrder,
      onClickNewValuation,
      onChangeEstimateField,
      onChangeActiveTab,
      onChangeSearch,
      searchTerm,
      filemode,
      selectedItemType,

      onClickGrid,
      onClickList,
      onClickUpload,
      onClickDownload,
      onCloseUploadModal,
      onClickNewFolder,
      onCloseDirectoryModal,
      onClickDelete,
      onClickEdit,
      didCreateDirectory,
      didRename,
      didUpload,
      onClickFileContextMenuItem,
      onClickPathBreadcrumb,
      onChangeNotes,
      didCreateJobSheetModal,
      onCloseJobSheetModal,
      directoryActionType,
      onCloseProjectCompleteModal,
      onDragEnter,
      onClickMove,
      onCloseMoveFilesModal,
      didMoveFiles,
      onClickViewEmail,

      selectedRetentionPaymentId,
      showRetentionPaymentModal,
      onCloseRetentionPaymentModal,
      didUpdateRetentionPayment,

      ramsRecordModal,
      onCloseRamsRecordModal,

      didSendEmail,
      onCloseEmailModal,

      valuationDateModal,
      onClickNewValuationDate,
      onCloseValuationDateModal,

    } = this.props;

    if (!project) return <Initializing />

    return (

      <Screen style={{ marginRight: 280, paddingRight: 30 }}>

        <Breadcrumbs
          project={project}
        />

        <Header
          project={project}
          onClickActionMenuItem={onClickActionMenuItem}
          onChangeNotes={onChangeNotes}
        />

        <Tabs
          activeKey={activeTab}
          defaultActiveKey="FILES"
          animated={false}
          onChange={onChangeActiveTab}
          type="card"
        >

          <Tabs.TabPane tab="Files" key="files">

            <Spin spinning={fetchingRootDirectories || fetchingDirectory}>

              <FileManager
                rootDirectories={rootDirectories}
                currentDirectoryPath={currentDirectoryPath}
                selectedItemType={selectedItemType}
                selectedItemPath={selectedItemPath}
                checkedItemPaths={checkedItemPaths}
                listItems={listItems}
                onSelectRootDirectory={onSelectRootDirectory}
                onSelectItem={onSelectItem}
                onDoubleClickItem={onDoubleClickItem}
                onToggleItemCheckbox={onToggleItemCheckbox}
                filemode={filemode}
                onClickGrid={onClickGrid}
                onClickList={onClickList}
                onClickUpload={onClickUpload}
                onClickDownload={onClickDownload}
                onClickNewFolder={onClickNewFolder}
                onClickDelete={onClickDelete}
                onClickEdit={onClickEdit}
                onClickFileContextMenuItem={onClickFileContextMenuItem}
                onClickPathBreadcrumb={onClickPathBreadcrumb}
                project={project}
                onDragEnter={onDragEnter}
                onClickMove={onClickMove}
              />

            </Spin>

          </Tabs.TabPane>

          <Tabs.TabPane tab="Estimates" key="estimates">

            <Estimates
              project={project}
              estimateStatuses={estimateStatuses}
              estimateTenderStatuses={estimateTenderStatuses}
              estimateLatestActions={estimateLatestActions}
              users={users}
              onChangeEstimateField={onChangeEstimateField}
              onClickNewEstimate={onClickNewEstimate}
              searchTerm={searchTerm}
              onChangeSearch={onChangeSearch}
            />

          </Tabs.TabPane>

          <Tabs.TabPane tab="Orders" key="orders">

            <Orders
              project={project}
              onClickNewOrder={onClickNewOrder}
              searchTerm={searchTerm}
              onChangeSearch={onChangeSearch}
            />

          </Tabs.TabPane>

          <Tabs.TabPane tab="Valuations" key="valuations">

            <Valuations
              project={project}
              onClickNewValuation={onClickNewValuation}
              onClickNewValuationDate={onClickNewValuationDate}
              searchTerm={searchTerm}
              onChangeSearch={onChangeSearch}
            />

          </Tabs.TabPane>

          <Tabs.TabPane tab="Invoices" key="invoices">

            <Invoices
              project={project}
              searchTerm={searchTerm}
              onChangeSearch={onChangeSearch}
            />

          </Tabs.TabPane>

          <Tabs.TabPane tab="Retentions" key="retention_payments">

            <RetentionPayments
              project={project}
              searchTerm={searchTerm}
              onChangeSearch={onChangeSearch}
            />

          </Tabs.TabPane>

          <Tabs.TabPane tab="Target Costs" key="target_costs">

            {activeTab === 'target_costs' && (
              <TargetCosts
                project={project}
              />
            )}

          </Tabs.TabPane>

          <Tabs.TabPane tab="RAMS" key="rams_records">

            {activeTab === 'rams_records' && (
              <RamsRecords
                project={project}
              />
            )}

          </Tabs.TabPane>

          {isAdmin() && (

            <Tabs.TabPane tab="Activity" key="activity">

              <Activity
                project={project}
                onClickViewEmail={onClickViewEmail}
              />

            </Tabs.TabPane>

          )}

            {isAdmin() && (

                <Tabs.TabPane tab="Emails" key="emails">
                    <Emails
                        componentView="1"
                        additionalFilter={{project_id: project.id}}
                    />
                </Tabs.TabPane>

            )}

        </Tabs>

        {showProjectModal && (
          <ProjectModal
            project={project}
            onClose={onCloseProjectModal}
            didUpdate={didUpdateProject}
            didDelete={didDeleteProject}
          />
        )}

        {showEstimateModal && (
          <EstimateModal
            projectId={project.id}
            onClose={onCloseEstimateModal}
            didCreate={didCreateEstimate}
          />
        )}

        {showOrderModal && (
          <OrderModal
            projectId={project.id}
            onClose={onCloseOrderModal}
            didCreate={didCreateOrder}
          />
        )}

        {showValuationModal && (
          <ValuationModal
            projectId={project.id}
            onClose={onCloseValuationModal}
            didCreate={didCreateValuation}
          />
        )}

        {valuationDateModal.show && (
          <ValuationDateModal
            {...valuationDateModal}
            project={project}
            onClose={onCloseValuationDateModal}
          />
        )}

        {showUploadModal && (
          <UploadModal
            projectId={project.id}
            currentDirectoryPath={currentDirectoryPath}
            onClose={onCloseUploadModal}
            didUpload={didUpload}
          />
        )}

        {showDirectoryModal && (

          <DirectoryModal
            projectId={project.id}
            currentDirectoryPath={currentDirectoryPath}
            selectedItemPath={selectedItemPath}
            selectedItemType={selectedItemType} // directory or file
            directoryActionType={directoryActionType} // new or edit
            onClose={onCloseDirectoryModal}
            didCreate={didCreateDirectory}
            didRename={didRename}
          />

        )}

        {showJobSheetModal && (
          <JobSheetModal
            projectId={project.id}
            onClose={onCloseJobSheetModal}
            didCreate={didCreateJobSheetModal}
          />
        )}

        {showProjectCompleteModal && (
          <ProjectCompleteModal
            projectId={project.id}
            onClose={onCloseProjectCompleteModal}
            didComplete={didUpdateProject}
          />
        )}

        {showMoveFilesModal && (
          <MoveFilesModal
            selectedFiles={[]}
            onClose={onCloseMoveFilesModal}
            didMove={didMoveFiles}
          />
        )}

        {showClientSiteModal && (
          <ClientSiteModal
            onClose={onCloseClientSiteModal}
            didUpdate={didUpdateClientSite}
            clientSite={project.client_site}
          />
        )}

        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didUpdate={didUpdateClientContact}
            clientContact={project.client_contact}
          />
        )}

        {showViewEmailModal && (
          <ViewEmailModal
            onClose={onCloseViewEmailModal}
            emailId={selectedEmailId}
          />
        )}

        {showRetentionPaymentModal && (
          <RetentionPaymentModal
            id={selectedRetentionPaymentId}
            onClose={onCloseRetentionPaymentModal}
            didUpdate={didUpdateRetentionPayment}
          />
        )}

        {ramsRecordModal.show && (
          <RamsRecordModal
            onClose={onCloseRamsRecordModal}
            projectId={project.id}
            ramsRecordId={ramsRecordModal.ramsRecordId}
          />
        )}

        {showEmailModal && (
          <EmailProjectInformationModal
            project={project}
            onClose={onCloseEmailModal}
            didSend={didSendEmail}
          />
        )}

        <Sidebar />

      </Screen>

    )
  }
}
