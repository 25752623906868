
import React from 'react'
import {Field} from 'redux-form'
import {Tag, Upload} from 'antd'
import {InputField, SelectField, TextAreaField, SwitchField} from 'FormFields'
import {UploadOutlined} from '@ant-design/icons'
import LogoStyles from './LogoStyle.css';


const labelColSpan = 6
const wrapperColSpan = 14

type Props = {
    id: ?any,
    team_id: ?any,
    owner_id: ?any,
    owner_type: ?any,
    project_id: ?any,
    to: string,
    cc: string,
    bcc: string,
    subject: string,
    body: string,
    html: string,
    status: string,
    email_attachments: ?any,
    opened: boolean,
    bounced: boolean,
    sent_at: string,
    create_at: string,
    deleted_at: string,
}

export default class extends React.PureComponent<Props> {

    render() {

        const {
            id,
            team_id,
            owner_id,
            owner_type,
            project_id,
            subject,
            to,
            cc,
            bcc,
            body,
            html,
            sent_by_user_id,
            status,
            email_attachments,
            opened,
            bounced,
            sent_at,
            created_at,
            updated_at,
            submitting,
        } = this.props

	let email_attachments_ar = [];
	if (Array.isArray(email_attachments))
	{
	 	console.log('---------------');
		console.log(email_attachments);
		email_attachments_ar = email_attachments;
	}
	

/*
 *
                {email_attachments.map((item, key) => (
                        <span key={key}>
                                {email_attachments[key].id}}
                        </span>
                ))}

*/

        return (

            <div>

                <Field
                    required
                    component={SelectField}
                    name='status'
                    label='Status'
                    options={[
                        { label: <Tag color='red'>Hold</Tag>, value: 'hold' },
                        { label: <Tag color='blue'>Pending</Tag>, value: 'pending' },
                        { label: <Tag color='grey'>Deleted</Tag>, value: 'deleted' },
                        { label: <Tag color='green'>Sent</Tag>, value: 'sent' },
                    ]}
                    disabled={submitting}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                />

                <Field
                    component={SwitchField}
                    name="opened"
                    label="Opened"
                    disabled={true}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    fieldCol={{ span: 3 }}
                />

                <Field
                    required
                    component={InputField}
                    name="to"
                    label="To"
                    mode="tags"
                    autoComplete="off"
                    disabled={submitting}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    fieldCol={{ span: 22 }}
                    afterCol={{ span: 2 }}
                />

                <Field
                    component={InputField}
                    name="cc"
                    label="Cc"
                    mode="tags"
                    autoComplete="off"
                    disabled={submitting}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    fieldCol={{ span: 22 }}
                    afterCol={{ span: 2 }}

                />

                <Field
                    component={InputField}
                    name="bcc"
                    label="Bcc"
                    mode="tags"
                    autoComplete="off"
                    disabled={submitting}
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    fieldCol={{ span: 22 }}
                    afterCol={{ span: 2 }}
                />

                <Field
                    required
                    component={InputField}
                    name="subject"
                    label="Subject"
                    disabled={submitting}
                    autoComplete="off"
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    fieldCol={{ span: 22 }}
                    afterCol={{ span: 2 }}
                />

                <Field
                    required
                    component={TextAreaField}
                    name="body"
                    label="Body"
                    disabled={submitting}
                    autoComplete="off"
                    labelCol={{ span: labelColSpan }}
                    wrapperCol={{ span: wrapperColSpan }}
                    fieldCol={{ span: 22 }}
                    afterCol={{ span: 2 }}
                    autoSize={{
                        minRows: 10,
                        maxRows: 17
                    }}
                />
	
	        <div style={{ marginLeft: 85, marginTop: 10, marginRight: 45 }}>
			Attachments:<br />
                	{email_attachments_ar.map((item, key) => (
                        	<span style={{ marginLeft: 75}}>
                                	{email_attachments_ar[key].file_name}
                        	</span>
                	))}
		</div>
            </div>



        )

    }

}

