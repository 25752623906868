exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3Ut6g5LAeAoEbTOOlXy07k tbody td {\n  border-right: 1px solid #f0f0f0;\n}\n\n._1PvTO-vxWE9BxQOAYr_XAV {\n  background-color: #9ef0ff !important;\n}\n\n._3N1oRNVZ-HfUb8RCN4Br_p {\n  background-color: #ffff9e !important;\n}\n\n.LqyMaUBiBv68HVjvOzZRb {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  cursor: pointer;\n}\n\n._33pbbkdvS9l-64qcC6LNpJ {\n  font-size: 13px;\n  padding: 16px 16px;\n}\n\n.IssWcBnrBRwz4DypxC991 {\n  display: flex;\n  align-items: flex-start;\n}\n\n.IssWcBnrBRwz4DypxC991 .ant-badge {\n  position: relative;\n  top: 4px;\n  margin-right: 15px;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/Planner/Components/SubcontractorTable/styles.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;CACjC;;AAED;EACE,qCAAqC;CACtC;;AAED;EACE,qCAAqC;CACtC;;AAED;EACE,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,+BAA+B;EAC/B,gBAAgB;CACjB;;AAED;EACE,gBAAgB;EAChB,mBAAmB;CACpB;;AAED;EACE,cAAc;EACd,wBAAwB;CACzB;;AAED;EACE,mBAAmB;EACnB,SAAS;EACT,mBAAmB;CACpB","file":"styles.css","sourcesContent":[".table tbody td {\n  border-right: 1px solid #f0f0f0;\n}\n\n.today {\n  background-color: #9ef0ff !important;\n}\n\n.weekend {\n  background-color: #ffff9e !important;\n}\n\n.cell {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  cursor: pointer;\n}\n\n.cellBody {\n  font-size: 13px;\n  padding: 16px 16px;\n}\n\n.multiple {\n  display: flex;\n  align-items: flex-start;\n}\n\n.multiple :global(.ant-badge) {\n  position: relative;\n  top: 4px;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "_3Ut6g5LAeAoEbTOOlXy07k",
	"today": "_1PvTO-vxWE9BxQOAYr_XAV",
	"weekend": "_3N1oRNVZ-HfUb8RCN4Br_p",
	"cell": "LqyMaUBiBv68HVjvOzZRb",
	"cellBody": "_33pbbkdvS9l-64qcC6LNpJ",
	"multiple": "IssWcBnrBRwz4DypxC991"
};