import { combineReducers } from 'redux'

import { reducer as emails } from './State/emails'
import { reducer as fetching } from './State/fetching'
import { reducer as searchTerm } from './State/searchTerm'
import { reducer as filters } from './State/filters'
import { reducer as sorting } from './State/sorting'
import { reducer as pagination } from './State/pagination'
import { reducer as emailModal } from './State/emailModal'
import { reducer as additionalFilter } from './State/filters'

export default combineReducers({
    additionalFilter,
    emails,
  fetching,
  searchTerm,
  filters,
  sorting,
  pagination,
  emailModal,
})
