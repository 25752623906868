exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1NM-MTBXb-gPbdRZJ7Epic {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  cursor: pointer;\n    overflow: hidden;\n}\n\n._3yDYMWqVFW4_CXV7r03YcN {\n  display: flex;\n  align-items: flex-start;\n  font-size: 15px;\n  padding: 16px 16px;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/Planner/Components/SubcontractorCell/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,+BAA+B;EAC/B,gBAAgB;IACd,iBAAiB;CACpB;;AAED;EACE,cAAc;EACd,wBAAwB;EACxB,gBAAgB;EAChB,mBAAmB;CACpB","file":"styles.css","sourcesContent":[".cell {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  cursor: pointer;\n    overflow: hidden;\n}\n\n.cellBody {\n  display: flex;\n  align-items: flex-start;\n  font-size: 15px;\n  padding: 16px 16px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cell": "_1NM-MTBXb-gPbdRZJ7Epic",
	"cellBody": "_3yDYMWqVFW4_CXV7r03YcN"
};