export default [
  {"name":"Dulux Vinyl Matt","value":"Dulux Vinyl Matt"},
  {"name":"Dulux Oil Eggshell","value":"Dulux Oil Eggshell"},
  {"name":"Dulux Satinwood","value":"Dulux Satinwood"},
  {"name":"Dulux Diamond Matt","value":"Dulux Diamond Matt"},
  {"name":"Dulux Diamond Eggshell","value":"Dulux Diamond Eggshell"},
  {"name":"Dulux Super Matt","value":"Dulux Super Matt"},
  {"name":"Dulux Durable Flat Matt","value":"Dulux Durable Flat Matt"},
  {"name":"Dulux Metalshield","value":"Dulux Metalshield"},
  {"name":"Dulux Masonry","value":"Dulux Masonry"},
  {"name":"Dulux Ultra Matt","value":"Dulux Ultra Matt"},
  {"name":"Dulux Weathershield","value":"Dulux Weathershield"},
  {"name":"Armstead Vinyl Matt","value":"Armstead Vinyl Matt"},
  {"name":"Armstead Contract Matt","value":"Armstead Contract Matt"},
  {"name":"Armstead Acrylic Eggshell","value":"Armstead Acrylic Eggshell"},
  {"name":"Zinsser All Coat Water Based","value":"Zinsser All Coat Water Based"},
  {"name":"Zinsser Bulls eye 1-2-3","value":"Zinsser Bulls eye 1-2-3"},
  {"name":"Thames Coatings Tc266","value":"Thames Coatings Tc266"},
  {"name":"Upol Easy 1 Filler","value":"Upol Easy 1 Filler"},
  {"name":"Thames Coatings Aqua Grip","value":"Thames Coatings Aqua Grip"},
  {"name":"Elite Dry Fall Water Base","value":"Elite Dry Fall Water Base"},
  {"name":"Tikkurila Optiva Colour","value":"Tikkurila Optiva Colour"},
  {"name":"Tikkurila Optiva Matt 5","value":"Tikkurila Optiva Matt 5"},
  {"name":"Tikkurila Anti Reflex","value":"Tikkurila Anti Reflex"},
  {"name":"Tikkurila Panssari Akva","value":"Tikkurila Panssari Akva"},
  {"name":"Tikkurila Otex Akva","value":"Tikkurila Otex Akva"},
  {"name":"Tikkurila Everal Aqua Semi Matt","value":"Tikkurila Everal Aqua Semi Matt"},
  {"name":"Tikkurila Optiva Ceramic Super Matt 3","value":"Tikkurila Optiva Ceramic Super Matt 3"},
  {"name":"Selemix DTM","value":"Selemix DTM"},
]
