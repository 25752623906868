import React from 'react';
import { Field } from 'redux-form';
import { InputField, InputNumberField, CheckboxField, DatePickerField, SelectField, TextAreaField } from 'FormFields';
import { expiries } from '../../../Config/expiries'

const labelColSpan = 8;
const wrapperColSpan = 14;

type Props = {
  submitting: boolean,
  drives: boolean,
  coVehicle: boolean,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      submitting,
      drives,
      coVehicle,
    } = this.props;

    return (

      <div style={{ display: 'flex' }}>

        <div style={{ flex: 1 }}>

          <Field
            required
            component={InputField}
            name="name"
            label="Name"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputField}
            name="group"
            label="Group"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={SelectField}
            name="type"
            label="Type"
            disabled={submitting}
            options={[
              { label: 'Decorator', value: 'Decorator' },
              { label: 'Sprayer', value: 'Sprayer' },
            ]}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            required
            component={InputField}
            name="telephone"
            label="Telephone"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            required
            component={InputField}
            name="email"
            label="Email"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={CheckboxField}
            name="is_multiple"
            label=" "
            text="Team"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <Field
            component={CheckboxField}
            name="is_ltd_company"
            label=" "
            text="Ltd Company"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <Field
            component={CheckboxField}
            name="drives"
            label=" "
            text="Drives"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          {!!drives && (

            <React.Fragment>

              <Field
                component={CheckboxField}
                name="co_vehicle"
                label=" "
                text="Company Vehicle"
                disabled={submitting}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
                colon={false}
              />

              {!!coVehicle && (

                <Field
                  required
                  component={InputField}
                  name="co_vehicle_reg"
                  label="Registration"
                  disabled={submitting}
                  autoComplete="off"
                  labelCol={{ span: labelColSpan }}
                  wrapperCol={{ span: wrapperColSpan }}
                />

              )}

            </React.Fragment>

          )}

          <br />
          <br />

          <Field
            component={InputField}
            name="address_line_1"
            placeholder="Address"
            label="Address"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputField}
            name="address_line_2"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />


          <Field
            component={InputField}
            name="address_line_3"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />


          <Field
            component={InputField}
            name="town_or_city"
            placeholder="Town / City"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <Field
            component={InputField}
            name="county"
            placeholder="County"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <Field
            component={InputField}
            name="postcode"
            placeholder="Postcode"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <br />
          <br />

          <Field
            component={InputField}
            name="user.full_name"
            label="User"
            disabled
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={DatePickerField}
            name="deactivated_at"
            label="Deactivated"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            getPopupContainer={triggerNode => triggerNode.parentElement}
          />

        </div>

        <div style={{ width: 450 }}>

          <Field
            component={InputNumberField}
            name="hourly_rate"
            label="Hourly Rate (£)"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputNumberField}
            name="day_rate"
            label="Day Rate (£)"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            required
            component={SelectField}
            name="card_type"
            label="Card Type"
            disabled={submitting}
            options={[
              { label: 'Red', value: 'Red' },
              { label: 'Blue', value: 'Blue' },
              { label: 'Green', value: 'Green' },
              { label: 'White', value: 'White' },
              { label: 'Gold', value: 'Gold' },
              { label: 'Black', value: 'Black' },
            ]}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputNumberField}
            name="liability_insurance"
            label="Liability Insurance (£)"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={CheckboxField}
            name="is_supervisor"
            label=" "
            text="Supervisor"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <Field
            component={CheckboxField}
            name="is_trainee"
            label=" "
            text="Trainee"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <br />
          <br />

          {Object.keys(expiries).map(key => (

            <Field
              key={key}
              component={DatePickerField}
              name={key}
              label={expiries[key].title}
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
            />

          ))}

          <br />
          <br />

          <Field
            required
            component={SelectField}
            name="availability"
            label="Availability"
            mode='multiple'
            disabled={submitting}
            options={[
              { label: 'Mon', value: 'Mon' },
              { label: 'Tue', value: 'Tue' },
              { label: 'Wed', value: 'Wed' },
              { label: 'Thu', value: 'Thu' },
              { label: 'Fri', value: 'Fri' },
              { label: 'Sat', value: 'Sat' },
              { label: 'Sun', value: 'Sun' },
            ]}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={TextAreaField}
            name="notes"
            label="Notes"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

        </div>

      </div>

    )
  }
}
