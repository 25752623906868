exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2X6a9ehGKmp9bJnaytZ2dn {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-top: 25px;\n  border-top: 1px solid #DDD;\n}\n\n._1ET2qBzYegqHwxWK_-Oz6L {\n  margin-right: 40px;\n}\n\n._9TdrTtuOWQZ35XJ-5Srm0 {\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}\n\n.EuDTujmZhCAkwD2G_oSsn {\n  font-size: 15px;\n  font-weight: 500;\n  color: #444;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/RetentionPayments/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,kBAAkB;EAClB,2BAA2B;CAC5B;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;CACb;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;CACb","file":"Component.css","sourcesContent":[".footer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-top: 25px;\n  border-top: 1px solid #DDD;\n}\n\n.total {\n  margin-right: 40px;\n}\n\n.label {\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}\n\n.value {\n  font-size: 15px;\n  font-weight: 500;\n  color: #444;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "_2X6a9ehGKmp9bJnaytZ2dn",
	"total": "_1ET2qBzYegqHwxWK_-Oz6L",
	"label": "_9TdrTtuOWQZ35XJ-5Srm0",
	"value": "EuDTujmZhCAkwD2G_oSsn"
};