import { message } from 'antd'
import _props from '../props'
import { createRamsRecord, updateRamsRecord } from '../../../HttpRequests/ramsRecords'
import handleException from '../../../Core/Helpers/handleException'

export default (values) => async (dispatch, getState) => {

  try {

    const { projectId, ramsRecordId, onClose } = _props.get()

    const { files, removedFileIds } = getState().modals.RamsRecord

    const data = new FormData();

    if (projectId) data.append('project_id', projectId)

    data.append('site_access', values.site_access)
    data.append('scope', values.scope)
    data.append('spray_timing', values.spray_timing)

    values.training.forEach((item) => {
      data.append('training[]', item)
    })

    values.ppe.forEach((item) => {
      data.append('ppe[]', item)
    })
    data.append('nearest_ae_name', values.nearest_ae_name)
    data.append('nearest_ae_address', values.nearest_ae_address)

    data.append('valid_from', values.valid_from)
    data.append('valid_to', values.valid_to)
    data.append('notes', values.notes || '')

    values.services.forEach((item) => {
      data.append('services[]', item)
    })

    values.access_equipment.forEach((item) => {
      data.append('access_equipment[]', item)
    })

    values.paints.forEach((item) => {
      data.append('paints[]', item)
    })

    files.filter(file => file.status !== 'done').forEach((file) => {
      data.append('files[]', file.originFileObj)
    })

    removedFileIds.forEach(id => {
      data.append('removed_file_ids[]', id)
    })

    if (ramsRecordId) {

      await updateRamsRecord({ id: ramsRecordId, data })

      message.success("RAMS Updated")

      onClose(true)

    } else {

      await createRamsRecord({ data })

      message.success("RAMS Created")

      onClose(true)

    }

  } catch (error) {

    handleException(error)

  }

}

