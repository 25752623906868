exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._31sop8fDILP7xoplSh-rkk {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-left: 30px;\n  margin-right: 65px;\n}\n\n._6abyRl_nvS22pfxhi16Lo {\n  flex: 1;\n  display: flex;\n}\n\n.CZLGdcNtH9awqWMEhJXq0 {\n  font-size: 24px;\n  font-weight: 600;\n  text-decoration: underline;\n}\n\n._2Ywev4gDhR-1fNvOoyjQyN {\n  display: none;\n  font-size: 24px;\n  font-weight: 600;\n  margin-left: 8px;\n  cursor: pointer;\n}\n\n._6abyRl_nvS22pfxhi16Lo:hover ._2Ywev4gDhR-1fNvOoyjQyN {\n  display: block;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/SubInvoice/Components/Items/Components/SubValuation/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;CACpB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,2BAA2B;CAC5B;;AAED;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;CACjB;;AAED;EACE,eAAe;CAChB","file":"styles.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-left: 30px;\n  margin-right: 65px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.reference {\n  font-size: 24px;\n  font-weight: 600;\n  text-decoration: underline;\n}\n\n.actions {\n  display: none;\n  font-size: 24px;\n  font-weight: 600;\n  margin-left: 8px;\n  cursor: pointer;\n}\n\n.left:hover .actions {\n  display: block;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_31sop8fDILP7xoplSh-rkk",
	"left": "_6abyRl_nvS22pfxhi16Lo",
	"reference": "CZLGdcNtH9awqWMEhJXq0",
	"actions": "_2Ywev4gDhR-1fNvOoyjQyN"
};