export default (values) => {

  const errors = {};

  if (!values.name) errors.name = 'Name is required';

    if (!values.telephone) errors.telephone = 'Telephone is required';
    if (!values.email) errors.email = 'Email is required';

  if (values.drives && values.co_vehicle) {
    if (!values.co_vehicle_reg) errors.co_vehicle_reg = 'Registration is required';
  }

  if (!values.hourly_rate && !values.day_rate) {
    errors.hourly_rate = 'Hourly rate or day rate is required';
    errors.day_rate = 'Hourly rate or day rate is required';
  }

  if (!values.card_type) errors.card_type = 'Card type is required';

  return errors;

}
