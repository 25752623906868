import {message} from 'antd'
import {updateEmail} from '../../../HttpRequests/emails'

export default (values) => async (dispatch, getState) => {


//    if (values.processor == "FileUpload")
//    {
//        values.value = getState().modals.Email.logo;
//        values.value = values.value.replace("/emails/files/", "");
//    }

    const {id} = values
//    const data = values;
    const data = {
        status: values.status,
        subject: values.subject,
        to: values.to,
        cc: values.cc,
        bcc: values.bcc,
        body: values.body,
    }

    const response = await updateEmail({id, data})

    const email = response.data

    message.success('Email updated')

    return email

}
