import {message} from 'antd'
import {createEmail} from '../../../HttpRequests/emails'

export default (values) => async (dispatch, getState) => {


    const {id} = values

    if (values.processor == "FileUpload")
    {
        values.value = getState().modals.Email.logo;
        values.value = values.value.replace("/emails/files/", "");
    }

    const data = {
        value: values.value,
        email_id: values.id,
    }

    const response = await createEmail({data})

    const email = response.data

    message.success('Email created')

    return email

}
