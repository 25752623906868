// @flow
import * as React from 'react';
import { push } from 'connected-react-router';
import { getTeamUrl } from 'Common';
import { isSubContractor } from 'Common';

type Props = {
  dispatch: Function
}

export default class extends React.PureComponent<Props> {

    /** of its subby, ensure they go direct to rams **/

    componentDidMount = () => {
        if (isSubContractor())
        {
            this.props.dispatch(push(getTeamUrl('rams')))
        }
        else
        {
            this.props.dispatch(push(getTeamUrl('estimates')))
        }
      };

  render = () => <div />

}
