exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SVTfesAeuJ4pO7ZlPoq0M {\n  border: 1px solid #E8E8E8;\n  background-color: white;\n  margin-top: 20px;\n}\n\n.j9d5yxubyNhxUDvLWXWk3 {\n  display: flex;\n  flex-direction: row;\n  height: 700px;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/ProjectView/Components/FileManager/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,wBAAwB;EACxB,iBAAiB;CAClB;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,cAAc;CACf","file":"index.css","sourcesContent":[".wrapper {\n  border: 1px solid #E8E8E8;\n  background-color: white;\n  margin-top: 20px;\n}\n\n.body {\n  display: flex;\n  flex-direction: row;\n  height: 700px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "SVTfesAeuJ4pO7ZlPoq0M",
	"body": "j9d5yxubyNhxUDvLWXWk3"
};