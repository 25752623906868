import { createAction, handleActions } from 'redux-actions';

const loadUserSettings = createAction("CORE/USER_SETTINGS/LOAD_USER_SETTINGS");
const clearUserSettings = createAction("CORE/USER_SETTINGS/CLEAR_USER_SETTINGS");

const defaultState = []

const reducer = handleActions(
    {
        [loadUserSettings]: (state, action) => action.payload,
        [clearUserSettings]: () => []
    },
    defaultState
);

export { loadUserSettings, clearUserSettings, reducer };
