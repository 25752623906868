import React from "react"
import PropTypes from "prop-types"
import Screen from '../../Components/Screen'
import Header from "./Components/Header"
import Filters from "./Components/Filters"
import Table from "./Components/Table"
import RamsRecordModal from '../../Modals/RamsRecord'

export default class _RamsRecords_ extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    ramsRecordModal: PropTypes.object.isRequired,
    onCloseRamsRecordModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render () {

    const {
      ramsRecordModal,
      onCloseRamsRecordModal,
    } = this.props

    return (

      <Screen>

        <Header />

        <Filters />

        <Table />

        {ramsRecordModal.show && (
          <RamsRecordModal
            ramsRecordId={ramsRecordModal.ramsRecordId}
            onClose={onCloseRamsRecordModal}
          />
        )}

      </Screen>

    )
  }
}
