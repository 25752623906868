
import React from 'react'
import {Field} from 'redux-form'
import {Tag, Upload} from 'antd'
import {InputField, SelectField, TextAreaField} from 'FormFields'
import {UploadOutlined} from '@ant-design/icons'

const labelColSpan = 6
const wrapperColSpan = 14

type Props = {
    id: ?any,
    rams_html: string,
    onUpload: Function,
}

export default class extends React.PureComponent<Props> {

    render() {

        const {
            id,
            rams_html,
        } = this.props

        return (
            <div class='_rams_html' dangerouslySetInnerHTML={{ __html: rams_html }} />
        )
    }

}

