import { connect } from 'react-redux'

import RamsSignings from './RamsSignings'

import componentDidMount from './Handlers/componentDidMount'
import onChangeSearch from './Handlers/onChangeSearch'
import onSubmitSearch from './Handlers/onSubmitSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'
import onClickNewRamsSigning from './Handlers/onClickNewRamsSigning'
import onCloseRamsSigningModal from './Handlers/onCloseRamsSigningModal'
import didCreateRamsSigning from './Handlers/didCreateRamsSigning'
import didUpdateRamsSigning from './Handlers/didUpdateRamsSigning'
import didDeleteRamsSigning from './Handlers/didDeleteRamsSigning'
//import didDeactivateRamsSigning from './Handlers/didDeactivateRamsSigning'
//import didReactivateRamsSigning from './Handlers/didReactivateRamsSigning'

const mapStateToProps = (state) => ({
  ramsSignings: state.screens.RamsSigning.ramsSignings,
  fetching: state.screens.RamsSigning.fetching,
  searchTerm: state.screens.RamsSigning.searchTerm,
  filters: state.screens.RamsSigning.filters,
  pagination: state.screens.RamsSigning.pagination,
  ramsSigningModal: state.screens.RamsSigning.ramsSigningModal,
})

const actionCreators = {
  componentDidMount,
  onChangeTable,
  onCloseRamsSigningModal,
  didCreateRamsSigning,
  onClickNewRamsSigning,
  onChangeSearch,
  onSubmitSearch,
  onClickRow,
  onChangeFilter,
  didUpdateRamsSigning,
}

export default connect(mapStateToProps, actionCreators)(RamsSignings)

