import { combineReducers } from 'redux';

import Project from './Project/reducer';
import Estimate from './Estimate/reducer'
import Order from './Order/reducer'
import Valuation from './Valuation/reducer';
import Upload from './Upload/reducer';
import EstimateDuplicate from './EstimateDuplicate/reducer';
import Invoice from './Invoice/reducer';
import MoveFiles from './MoveFiles/reducer'
import ViewEmail from './ViewEmail/reducer'
import EmailEstimate from './EmailEstimate/reducer'
import EmailInvoice from './EmailInvoice/reducer'
import EmailOrderAcknowledgement from './EmailOrderAcknowledgement/reducer'
import EmailValuation from './EmailValuation/reducer'
import EmailProjectInformation from './EmailProjectInformation/reducer'
import SelectEstimateOptionals from './SelectEstimateOptionals/reducer'
import RamsRecord from './RamsRecord/reducer'
import Timesheet from './Timesheet/reducer'
import SubOrder from './SubOrder/reducer'
import User from './User/reducer'
import Setting from './Setting/reducer'
import Email from './Setting/reducer'
import RamsSigning from './RamsSigning/reducer'

export default combineReducers({
  Project,
  Estimate,
  Order,
  Valuation,
  Upload,
  EstimateDuplicate,
  Invoice,
  MoveFiles,
  ViewEmail,
  EmailEstimate,
  EmailInvoice,
  EmailOrderAcknowledgement,
  EmailValuation,
  EmailProjectInformation,
  SelectEstimateOptionals,
  RamsRecord,
  Timesheet,
  SubOrder,
  User,
  Setting,
  Email,
  RamsSigning,
})
