exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3RmwpTAQK1q4EUxR863XjP {\n  text-align: right !important;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/Insights/Components/ProjectsTable.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;CAC9B","file":"ProjectsTable.css","sourcesContent":[".numericColumn {\n  text-align: right !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"numericColumn": "_3RmwpTAQK1q4EUxR863XjP"
};