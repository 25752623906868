import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openEmailModal = createAction('SCREENS/EMAILS/OPEN_EMAIL_MODAL')
const closeEmailModal = createAction('SCREENS/EMAILS/CLOSE_EMAIL_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openEmailModal]: (state, action) => ({
      ...state,
      show: true,
      id: get(action, 'payload.id', null),
    }),
    [closeEmailModal]: () => defaultState,
  },
  defaultState,
)

export { openEmailModal, closeEmailModal, reducer }
