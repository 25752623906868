import fetchEmails from '../Services/fetchEmails'
import { setPagination } from '../State/pagination'

export default () => (dispatch) => {

  dispatch(setPagination({ current: 1 }))
console.log("IN ON SUBMIT");
  dispatch(fetchEmails())

}
