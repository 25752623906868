exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1KBGbO0dTUVrlPfoWSucCD {\n  height: 100%;\n  margin-left: 30px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #CCC;\n}\n\n._36TM84i2yUadKvdnCM7did {\n  margin: 0px;\n  flex: 1;\n  font-size: 16px;\n  font-weight: 600;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/ValuationView/Components/Items/Components/OrderFooter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,oBAAoB;EACpB,8BAA8B;CAC/B;;AAED;EACE,YAAY;EACZ,QAAQ;EACR,gBAAgB;EAChB,iBAAiB;CAClB","file":"OrderFooter.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #CCC;\n}\n\n.total {\n  margin: 0px;\n  flex: 1;\n  font-size: 16px;\n  font-weight: 600;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1KBGbO0dTUVrlPfoWSucCD",
	"total": "_36TM84i2yUadKvdnCM7did"
};