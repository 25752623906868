
import React from 'react'
import {Field} from 'redux-form'
import {Tag, Upload} from 'antd'
import {InputField, SelectField, TextAreaField} from 'FormFields'
import {UploadOutlined} from '@ant-design/icons'
import LogoStyles from './LogoStyle.css';

const labelColSpan = 6
const wrapperColSpan = 14

type Props = {
    id: ?any,
    type: string,
    processor_json: string,
    value: string,
    title: string,
    processor: string,
    validation_json: string,
    submitting: boolean,
    logo: string,
    onUpload: Function,
}

export default class extends React.PureComponent<Props> {

    render() {

        const {
            id,
            type,
            processor,
            processor_json,
            title,
            validation_json,
            submitting,
            logo,
            onUpload,
            value,
        } = this.props

        let imgValue;
        imgValue = "";
        if (processor == "FileUpload" && (value != undefined || value != ""))
            imgValue = "/settings/files/" + value;

        let _processor_json;
        if (processor_json != undefined && processor_json != "")
        {
            _processor_json = JSON.parse(processor_json);
        }
        else if (processor == 'SelectField')
            _processor_json = [{"value": "", "label": "DEFAULT"}];

        function getAssociatedRowFields (rowNumber)
        {
            let output = [];
            for (let ccount = 0; ccount < _processor_json.columnNames.length; ccount ++)
            {
                output.push(
                    <div style={{ flex: 1 }}>
                        <Field
                            component={InputField}
                            name={"value[" + rowNumber + "][" + _processor_json.columnNames[ccount].key + "]"}
                            label={_processor_json.columnNames[ccount].value + " " + (rowNumber+1)}
                            disabled={submitting}
                            labelPosition="top"
                            labelCol={{ span: 24 }}
                            wrapperCol={{span: 25}}
                        />
                    </div>
                )
            }
            return output;
        }

        function getAssociatedRow (rowNumber)
        {
            let output = [];
            if (typeof _processor_json != "undefined") {

                output.push(
                    <div style={{ display: 'flex' }}>
                        {getAssociatedRowFields(rowNumber)}
                    </div>
                );

            }else{
                output.push(
                    <div style={{ display: 'flex' }}>

                        <div style={{ flex: 1 }}>
                            <Field
                                component={InputField}
                                name={"value[" + rowNumber + "][key]"}
                                label={"Key " + (rowNumber+1)}
                                disabled={submitting}
                                labelCol={{span: labelColSpan}}
                                wrapperCol={{span: wrapperColSpan}}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Field
                                component={InputField}
                                name={"value[" + rowNumber + "][value]"}
                                label={"Value "+ (rowNumber+1)}
                                disabled={submitting}
                                labelCol={{span: labelColSpan}}
                                wrapperCol={{span: wrapperColSpan}}
                            />
                        </div>

                    </div>
                );
            }

            return output;
        }

        function getAssociatedRows ()
        {
            let output = [];
            for (let i = 0; i < 10; i ++)
            {
                output.push(getAssociatedRow(i));
            }
            return output;
        }

        function getUploadDisplay (imgValue)
        {
            if (imgValue.match('gif') || imgValue.match('png') || imgValue.match('jpeg') || imgValue.match('jpg'))
            {
                return <img style={LogoStyles} className={LogoStyles.settingLogo} src={imgValue}/>
            }
            else if (imgValue !== "/settings/files/")
            {
                let vArray = imgValue.split(".");
                return <a href={"/settings/files/" + imgValue} target="_new">{vArray[1]} file</a>;
            }

        }

        return (

            <div>

                {processor === 'AssociativeJSON' && (
                    <div>
                        {getAssociatedRows()}
                    </div>
                )}


                {processor === 'TextAreaField' && (
                    <Field
                        required
                        component={TextAreaField}
                        name="value"
                        label={"Setting Value"}
                        disabled={submitting}
                        autoComplete="off"
                        labelCol={{span: labelColSpan}}
                        wrapperCol={{span: wrapperColSpan}}
                        fieldCol={{span: 22}}
                        afterCol={{span: 2}}
                        autoSize={{
                            minRows: 5,
                            maxRows: 17,
                        }}
                    />

                )}

                {processor === 'InputField' && (

                    <Field
                        component={InputField}
                        name="value"
                        label="Setting Value"
                        disabled={submitting}
                        labelCol={{span: labelColSpan}}
                        wrapperCol={{span: wrapperColSpan}}
                    />

                )}

                {processor === 'SelectField' && (

                    <Field
                        required
                        component={SelectField}
                        name="value"
                        label="Setting Value"
                        disabled={submitting}
                        options={_processor_json}
                        labelCol={{span: labelColSpan}}
                        wrapperCol={{span: wrapperColSpan}}
                    />

                )}

                {processor === 'CheckboxField' && (

                    //const poptions = JSON.parse(processor_json.options);
                    //Object.keys(poptions).forEach(function(key) {
                    //  poptions[key];
                    //});
                    <Field
                        component={CheckboxField}
                        name="value[]"
                        label="Setting Value"
                        text="Induction Required"
                        disabled={submitting}
                        labelCol={{span: labelColSpan}}
                        wrapperCol={{span: wrapperColSpan}}
                    />

                )}

                {processor === 'DisplayField' && (

                    //const poptions = JSON.parse(processor_json.options);
                    //Object.keys(poptions).forEach(function(key) {
                    //  poptions[key];
                    //});
                    <Field
                        component={InputField}
                        name="value"
                        label="Setting Value"
                        disabled="1"
                        labelCol={{span: labelColSpan}}
                        wrapperCol={{span: wrapperColSpan}}
                    />

                )}


                {processor === 'FileUpload' && (

                    <React.Fragment>

                        {imgValue != logo && (
                            <div>
                                {getUploadDisplay(imgValue)}
                            </div>
                        )}

                        <br/>
                        <Upload
                            name='file'
                            customRequest={onUpload}
                            showUploadList={false}
                            className={LogoStyles.uploaderPointer}
                        >
                            <UploadOutlined/>
                            {' Upload new ' + title}
                        </Upload>

                        <br/><img style={LogoStyles} className={LogoStyles.settingLogo} src={logo}/>

                    </React.Fragment>

                )}


            </div>

        )
        // Danny look here
        // all I want to do is set the value that is posted to the create / update

        //                  <Field
        //                     component={InputField}
        //                     name="value"
        //                     value={logo}
        //                 />
        //                <input type="hidden" name="value" value={logo} />
        // ^^ If I put hidden field in using a component, the value does not get set.
    }

}

