import React from 'react'
import {Table, Tag} from 'antd'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import styles from './Table.css'

type Props = {
    settings: [],
    fetching: boolean,
    pagination: {},
    onChangeTable: () => void,
    onClickRow: () => void,
}


function DisplayValue(props) {

    let v;
    if (props.value != undefined)
        v = props.value;
    else v = "";

    if (props.processor == "FileUpload" && v != "")
    {
        if (v.match('gif') || v.match('png') || v.match('jpeg') || v.match('jpg'))
                return DisplayImage("/settings/files/" + v);
        else
        {
            let vArray = v.split(".");
            return <a href={"/settings/files/" + v} target="_new">{vArray[1]} file</a>;
        }
    }
    else if (props.processor == "AssociativeJSON" && v != "")
    {
        v = JSON.parse(v);
        let loopLimit = 3;
        if (v.length < 3)
            loopLimit = v.length;

        let output = '';

        for (let i = 0; i < loopLimit; i ++)
        {
            if (typeof v[i].key != "undefined")
                output = output + v[i].key + ':' + v[i].value + ',\n';
            else{
                for (const property in v[i]) {
                    output = output + property + ': ' + v[i][property] + ',\n';
                }
            }
        }
        return output + '...';
    }
    else
    {
        if (v.length > 100)
            return v.substr(0,80) + '...';
        else	return v;
    }
}

function DisplayImage(logo) {
    return <img src={logo}/>
}

export default class extends React.PureComponent<Props> {

    render() {

        const {
            settings,
            fetching,
            pagination,
            onChangeTable,
            onClickRow,
        } = this.props

        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                sorter: false,
                width: '30%',
                render: (text, record) => record.title,
            },
            {
                title: 'Type',
                dataIndex: 'type',
                sorter: false,
                width: '15%',
                render: (text, record) => (
                    <div>
                        {record.type === 'user' && <Tag color='green'>User</Tag>}
                        {record.type === 'team' && <Tag color='gold'>Team</Tag>}
                        {record.type === 'system' && <Tag color='blue'>System</Tag>}
                    </div>
                ),
            },
            {
                title: 'Group',
                dataIndex: 'group',
                key: 'group',
                sorter: false,
                width: '15%',
                render: (text, record) => record.group,
            },
            {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                sorter: false,
                width: '40%',
                render: (text, record) => (
                    <DisplayValue value={record.value} processor={record.processor}/>
                ),
            },
        ]

        return (
            <Table
                columns={columns}
                dataSource={settings}
                rowKey='id'
                onChange={onChangeTable}
                bordered
                loading={fetching}
                className={styles.table}
                onRow={(record) => ({
                    onClick: () => onClickRow(record.id),
                })}
                style={{ whiteSpace: 'pre'}}
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    current: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
            />
        )

    }

}


