exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._SUb5FyJIbB03wytGCuxK {\n  height: 100%;\n  padding-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n._3M3_Pu_HoqJTJvA3XyPeTc {\n  cursor: pointer;\n  margin-left: 30px;\n  color: #999;\n  font-weight: 500;\n  font-size: 12px;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n._3M3_Pu_HoqJTJvA3XyPeTc:hover {\n  color: #1990FF;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/ValuationView/Components/Items/Components/GroupFooter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,oBAAoB;EACpB,0BAA0B;CAC3B;;AAED;EACE,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,0BAA0B;KACvB,uBAAuB;UAClB,kBAAkB;CAC3B;;AAED;EACE,eAAe;CAChB","file":"GroupFooter.css","sourcesContent":[".wrapper {\n  height: 100%;\n  padding-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.link {\n  cursor: pointer;\n  margin-left: 30px;\n  color: #999;\n  font-weight: 500;\n  font-size: 12px;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n.link:hover {\n  color: #1990FF;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_SUb5FyJIbB03wytGCuxK",
	"link": "_3M3_Pu_HoqJTJvA3XyPeTc"
};