exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2d8V4tU_-SB2HwP8INBk_f {\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.xf8kZuu-eBhDoj4bO2JC9 {\n  flex: 1;\n  margin: 0px;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/SubOrder/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,YAAY;CACb","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.header {\n  flex: 1;\n  margin: 0px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2d8V4tU_-SB2HwP8INBk_f",
	"header": "xf8kZuu-eBhDoj4bO2JC9"
};