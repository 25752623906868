import fetchEmails from '../Services/fetchEmails'
import {setProps} from "../../ProjectView/props";

//export default () => (dispatch) => {
export default (props: any) => async (dispatch: Function) => {

  setProps(props);

  dispatch(fetchEmails())

}
