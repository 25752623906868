exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n\n._1vQrh6OWa082CeJd2cNXlT[src=\"\"]\n{\n    display: none;\n}\n\n._3wQ3BDV1uM_J1XQEDnejKw\n{\n    cursor: pointer;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Modals/Email/Components/LogoStyle.css"],"names":[],"mappings":";;AAEA;;IAEI,cAAc;CACjB;;AAED;;IAEI,gBAAgB;CACnB","file":"LogoStyle.css","sourcesContent":["\n\n.emailLogo[src=\"\"]\n{\n    display: none;\n}\n\n.uploaderPointer\n{\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"emailLogo": "_1vQrh6OWa082CeJd2cNXlT",
	"uploaderPointer": "_3wQ3BDV1uM_J1XQEDnejKw"
};