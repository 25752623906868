exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2qiiwhofKBmd-guqamPDvJ {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._2YKtNw1wiMG2XlfwniP4X9 {\n  flex: 1;\n  display: flex;\n}\n\n._3zXH7DyjRIFdY4I6wo5swx {\n}\n\n._2rytucI-SEZS6AHV0tBhGq {\n  width: 280px;\n}\n\n._2RMvxkO5l1nB9iQxhh7IBZ {\n  margin-bottom: 12px;\n}\n\n._2RMvxkO5l1nB9iQxhh7IBZ:last-child {\n  margin-bottom: 4px;\n}\n\n._1MrKnao-7h-ZCTDtTdlnCi {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/SubValuations/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;CACC;;AAED;EACE,aAAa;CACd;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".controls {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.right {\n}\n\n.filters {\n  width: 280px;\n}\n\n.box {\n  margin-bottom: 12px;\n}\n\n.box:last-child {\n  margin-bottom: 4px;\n}\n\n.label {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"controls": "_2qiiwhofKBmd-guqamPDvJ",
	"left": "_2YKtNw1wiMG2XlfwniP4X9",
	"right": "_3zXH7DyjRIFdY4I6wo5swx",
	"filters": "_2rytucI-SEZS6AHV0tBhGq",
	"box": "_2RMvxkO5l1nB9iQxhh7IBZ",
	"label": "_1MrKnao-7h-ZCTDtTdlnCi"
};