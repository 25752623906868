exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2qR4e-IxGc0-s2MsvITgOI {\n  display: flex;\n  justify-content: flex-end;\n}\n\n._1tSTPygAVc-r6y6y5ovNjG {\n  margin-right: auto;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Modals/RetentionPayment/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;CAC3B;;AAED;EACE,mBAAmB;CACpB","file":"Component.css","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.deleteButton {\n  margin-right: auto;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "_2qR4e-IxGc0-s2MsvITgOI",
	"deleteButton": "_1tSTPygAVc-r6y6y5ovNjG"
};