import React from 'react'
import {Table, Tag} from 'antd'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import styles from './Table.css'
import moment from "moment/moment";

type Props = {
    ramsSignings: [],
    fetching: boolean,
    pagination: {},
    onChangeTable: () => void,
    onClickRow: () => void,
}


export default class extends React.PureComponent<Props> {

    render() {

        const {
            ramsSignings,
            fetching,
            pagination,
            onChangeTable,
            onClickRow,
        } = this.props

        const columns = [
            {
                title: 'Client / Site / Project',
                key: 'client.name',
                width: 252,
                sorter: true,
                fixed: 'left',
                render: (text, record) => (
                    <div>
                        <div className="ellipsis" style={{ width: 220 }}>
                            <strong>
                                {record.project.client_site.client.name}
                            </strong>
                        </div>
                        <div className="ellipsis" style={{ width: 220 }}>
                            {record.project.client_site.name}
                        </div>
                        <div className="ellipsis" style={{ width: 220 }}>
                            <strong>
                                {record.project.number}
                            </strong>
                        </div>
                    </div>
                ),
            },
            {
                title: 'Current',
                dataIndex: 'current',
                key: 'current',
                sorter: false,
                render: (text, record) => (
                    <div>
                        {record.current === '0' && <Tag color='red'>No</Tag>}
                        {record.current === '1' && <Tag color='green'>Yes</Tag>}
                        {record.current === 0 && <Tag color='red'>No</Tag>}
                        {record.current === 1 && <Tag color='green'>Yes</Tag>}
                    </div>
                ),
            },
            {
                title: 'Viewed',
                dataIndex: 'viewed',
                key: 'viewed',
                sorter: false,
                render: (text, record) => (
                    <div>
                        {record.viewed === '0' && <Tag color='red'>No</Tag>}
                        {record.viewed === '1' && <Tag color='green'>Yes</Tag>}
                        {record.viewed === 0 && <Tag color='red'>No</Tag>}
                        {record.viewed === 1 && <Tag color='green'>Yes</Tag>}
                    </div>
                ),
            },
            {
                title: 'Signed',
                dataIndex: 'signed',
                key: 'signed',
                sorter: false,
                render: (text, record) => (
                    <div>
                        {record.signed === '0' && <Tag color='red'>No</Tag>}
                        {record.signed === '1' && <Tag color='green'>Yes</Tag>}
                        {record.signed === 0 && <Tag color='red'>No</Tag>}
                        {record.signed === 1 && <Tag color='green'>Yes</Tag>}
                    </div>
                ),
            },
            {
                title: 'Date',
                key: 'date',
                width: 120,
                sorter: true,
                render: (text, record) => (
                    <div>
                        {record.created_at ? moment(record.created_at).format('DD MMM YYYY HH:mm:ss') : '--'}
                    </div>
                ),
            },
        ]
/*
            {
                title: 'Download',
                dataIndex: 'rams_pdf_created',
                key: 'rams_pdf_created',
                sorter: false,
                render: (text, record) => (
                    <div>
                        {record.rams_pdf_created === '0' && <Tag color='red'>Not available</Tag>}
                        {record.rams_pdf_created === '1' && <Tag color='green'><a href="">Download</a></Tag>}
                        {record.rams_pdf_created == 0 && <Tag color='red'>Not available</Tag>}
                        {record.rams_pdf_created == 1 && <Tag color='green'><a href="">Download</a></Tag>}
                    </div>
                ),
            },
 */
        return (
            <Table
                columns={columns}
                dataSource={ramsSignings}
                rowKey='id'
                onChange={onChangeTable}
                bordered
                loading={fetching}
                className={styles.table}
                onRow={(record) => ({
                    onClick: () => onClickRow(record.id),
                })}
                style={{ whiteSpace: 'pre'}}
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    current: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
            />
        )

    }

}


