// @flow
import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Wrapper from '../Components/Wrapper';
import Header from '../Components/Header';
import StartupScreen from '../Screens/Startup';
import LoginScreen from '../Screens/Login';
import LogoutScreen from '../Screens/Logout';
import RamsSigning from '../../Screens/RamsSigning';

type Props = {
  componentWillMount: () => void,
  isStarting: boolean,
  headerButtons: any,
  children:? React.Node
};

export default class Container extends React.Component<Props> {

  componentWillMount = () => this.props.componentWillMount()

  render () {

    const { isStarting, headerButtons, children } = this.props;

    return (

      <Wrapper>

        {isStarting && <StartupScreen />}

        {!isStarting && (

          <React.Fragment>

            <Header headerButtons={headerButtons} />

            <Switch>

                <Redirect exact from="/" to="/login" />

                <Route path="/sso" component={LoginScreen} />

                <Route path="/login" component={LoginScreen} exact />

                <Route path="/app/" render={() => children} />

                <Route path="/logout" component={LogoutScreen} exact />

            </Switch>

          </React.Fragment>

        )}

      </Wrapper>

    )
  }
}
