/* eslint-disable no-nested-ternary */
import { initialize } from 'redux-form'
import { findEmail } from '../../../HttpRequests/emails'
import { getProps } from '../props'
import { FORM_NAME } from '../Form'

export default () => async (dispatch) => {

  const { id } = getProps()
  const params = {
 	include: 'emailAttachments'
  }

  if (id) {

    const response = await findEmail({ id, params })
    const email = response.data.data

    dispatch(initialize(FORM_NAME, {
      ...email,
    }))

  } else {

    dispatch(initialize(FORM_NAME, {
      //
    }))

  }

}


