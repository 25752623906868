
import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'

export default class extends React.PureComponent {

  static propTypes = {
    filters: PropTypes.object.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
  }

  render () {

    const {
      filters,
      onChangeFilter,
    } = this.props

    const statusOptions = [
      { title: 'All', key: ''},
      { title: 'On Hold', key: 'hold'},
      { title: 'Pending', key: 'pending'},
      { title: 'Deleted', key: 'deleted'},
      { title: 'Sent', key: 'sent'},
    ]

    const openedOptions = [
        { title: 'All', key: '' },
        { title: 'Opened', key: '1'},
        { title: 'Unopened', key: '0'},
    ]

    return (

      <div class="_filter_bar" >

        <Radio.Group
          value={filters.status}
          onChange={(event) => onChangeFilter('status', event.target.value)}
        >

          {statusOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.key}
              key={option.key}
            >
              {option.title}
            </Radio.Button>
          ))}

        </Radio.Group>

        <div style={{ width: 10 }} />

        <Radio.Group
          value={filters.opened}
          onChange={(event) => onChangeFilter('opened', event.target.value)}
        >

          {openedOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.key}
              key={option.key}
            >
              {option.title}
            </Radio.Button>
          ))}

        </Radio.Group>

      </div>

    )

  }

}
