
import { getProps } from '../props'
import update from '../Services/update'

export default async (values, dispatch) => {

  const { onClose, didUpdate } = getProps()

  if (values.id) {

    const ramsSigning = await dispatch(update(values))
    onClose()
    if (didUpdate) didUpdate(ramsSigning)

  }

}
