import {uploadEmailsFile} from "../../../HttpRequests/emails";
import { setLogo } from '../State/logo'
import { getUser, getTeamUrl, getCurrentTeam, getTeams, goToTeam } from 'Common';
import {get} from "lodash";


export default (fileProps) => async (dispatch, getState) => {


    try {

        const {file} = fileProps;
        const id = getState().screens.Emails.emailModal.id;
        const data = new FormData()

        data.append('file', file);

        //await endpoint({ data })
        const returnedJSON = await uploadEmailsFile({
            id, data: data, config: {

                onUploadProgress: (progress) => {
                    /* const uploaded =  */
                    Math.floor((progress.loaded * 100) / progress.total);
                },

            }
        });

        const currentTeam = getCurrentTeam()
        const filePath = "/emails/files/" + returnedJSON.data;

        dispatch(setLogo(filePath))

    } catch (error) {

        throw error

    }

}
