exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1D917xwG_IcZG9jAeZQoiy {\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n._1bXEUjwB8UcMlRrqNn7aLW {\n  flex: 1;\n}\n\n._2H9wkq2p-ggyfHevLWp-um {\n  display: flex;\n}\n\n._2fQnO8yXdpotjrD0qQf0Wy {\n  margin: 0px;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/SubProject/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,cAAc;CACf;;AAED;EACE,YAAY;CACb","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.left {\n  flex: 1;\n}\n\n.right {\n  display: flex;\n}\n\n.header {\n  margin: 0px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1D917xwG_IcZG9jAeZQoiy",
	"left": "_1bXEUjwB8UcMlRrqNn7aLW",
	"right": "_2H9wkq2p-ggyfHevLWp-um",
	"header": "_2fQnO8yXdpotjrD0qQf0Wy"
};