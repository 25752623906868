exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3wDkBUshCFGkvJUYxa-ybk {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-left: 30px;\n  margin-right: 65px;\n}\n\n.ndxMZDVqICgdoC6ZCNPAH {\n  flex: 1;\n  font-size: 20px;\n  font-weight: 600;\n  text-decoration: underline;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/SubValuation/Components/Items/Components/SubOrder/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;CACpB;;AAED;EACE,QAAQ;EACR,gBAAgB;EAChB,iBAAiB;EACjB,2BAA2B;CAC5B","file":"styles.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-left: 30px;\n  margin-right: 65px;\n}\n\n.reference {\n  flex: 1;\n  font-size: 20px;\n  font-weight: 600;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3wDkBUshCFGkvJUYxa-ybk",
	"reference": "ndxMZDVqICgdoC6ZCNPAH"
};