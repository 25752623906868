exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2dIlaq1X52jmV0lNJIiIvt {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 5px;\n}\n\n._231QJHxvSzVUppH0BwHHxJ {\n  width: 80px;\n  font-weight: bold;\n}\n\n._9ni9GkK_ecYfFlkJ7zEJ3 {\n  flex: 1;\n}\n\n._3a43HVnIkuc3jBAVFqKOif {\n  margin-top: 20px;\n  border: 1px solid #DDD;\n  border-radius: 5px;\n  padding: 10px;\n  background-color: #FAFAFA;\n}\n\n.cyl_R-CVpb1zDWntbdSw6 {\n  color: #999;\n  margin-top: 15px;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Modals/ViewEmail/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,mBAAmB;CACpB;;AAED;EACE,YAAY;EACZ,kBAAkB;CACnB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,0BAA0B;CAC3B;;AAED;EACE,YAAY;EACZ,iBAAiB;CAClB","file":"Component.css","sourcesContent":[".infoBlock {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 5px;\n}\n\n.infoBlockLabel {\n  width: 80px;\n  font-weight: bold;\n}\n\n.infoBlockDetail {\n  flex: 1;\n}\n\n.body {\n  margin-top: 20px;\n  border: 1px solid #DDD;\n  border-radius: 5px;\n  padding: 10px;\n  background-color: #FAFAFA;\n}\n\n.sentAt {\n  color: #999;\n  margin-top: 15px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"infoBlock": "_2dIlaq1X52jmV0lNJIiIvt",
	"infoBlockLabel": "_231QJHxvSzVUppH0BwHHxJ",
	"infoBlockDetail": "_9ni9GkK_ecYfFlkJ7zEJ3",
	"body": "_3a43HVnIkuc3jBAVFqKOif",
	"sentAt": "cyl_R-CVpb1zDWntbdSw6"
};