exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2qXDj8_khPq5BvnuF_0h7G {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._3hT27zKFmLhdPeOdrCO8Ek {\n  flex: 1;\n  display: flex;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/SubProject/Components/Overview/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2qXDj8_khPq5BvnuF_0h7G",
	"left": "_3hT27zKFmLhdPeOdrCO8Ek"
};