exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2TT1dKcquHVcb2YF4nHTHp {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.AgEKCEFHxlaEXSaiPVgg {\n  flex: 1;\n  margin: 0px;\n}\n\n.lKAxgEcvvTnjxlogCQ9GI {\n  padding: 5px;\n  border-radius: 5px;\n  color: #FFF;\n  vertical-align: middle;\n  font-size: 12px;\n}", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/ValuationView/Components/Header/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,YAAY;CACb;;AAED;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;CACjB","file":"Component.css","sourcesContent":[".stack {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.heading {\n  flex: 1;\n  margin: 0px;\n}\n\n.statusTitle {\n  padding: 5px;\n  border-radius: 5px;\n  color: #FFF;\n  vertical-align: middle;\n  font-size: 12px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"stack": "_2TT1dKcquHVcb2YF4nHTHp",
	"heading": "AgEKCEFHxlaEXSaiPVgg",
	"statusTitle": "lKAxgEcvvTnjxlogCQ9GI"
};