
import { connect } from 'react-redux'
import { formValueSelector, isSubmitting } from 'redux-form'
import RamsSigning from './RamsSigning'

import { FORM_NAME } from './Form'

import componentWillMount from './Handlers/componentWillMount'
import onClickSubmit from './Handlers/onClickSubmit'
import onClickCancel from './Handlers/onClickCancel'
import onClickDelete from './Handlers/onClickDelete'
import onUpload from './Handlers/onUpload'

const mapStateToProps = (state) => ({

    rams_record: formValueSelector(FORM_NAME)(state, 'rams_record'),
    rams_html: formValueSelector(FORM_NAME)(state, 'rams_record.rams_html'),
    project: formValueSelector(FORM_NAME)(state, 'project'),
    submitting: isSubmitting(FORM_NAME)(state),

})
/*
    processor_json: formValueSelector(FORM_NAME)(state, 'rams_html'),
    title: formValueSelector(FORM_NAME)(state, 'title'),
    validation_json: formValueSelector(FORM_NAME)(state, 'validation_json'),
    submitting: isSubmitting(FORM_NAME)(state),
    logo: state.modals.RamsSigning.logo,
 */

const actionCreators = {
    componentWillMount,
    onClickSubmit,
    onClickCancel,
    onClickDelete,
    onUpload,
}


export default connect(mapStateToProps, actionCreators)(RamsSigning)
