import { message } from 'antd'
import { emailEstimate } from '../../../HttpRequests/estimates';
import { setFileList } from '../State/fileList';
import handleException from '../../../Core/Helpers/handleException';

export default (values) => async (dispatch, getState) => {

  try {

    const { id } = values;

    const data = new FormData();

    data.append('to', values.to.join(','));
    data.append('cc', values.cc.join(','));
    data.append('bcc', values.bcc.join(','));
    data.append('subject', values.subject);
    data.append('body', values.body);
    data.append('include_attachments', values.include_attachments);

    const { fileList } = getState().modals.EmailEstimate;

    fileList.forEach((file) => {
      data.append('files[]', file);
    });

    await emailEstimate({ id, data });

    message.success("Mail Queued");

    dispatch(setFileList([]));

    return true;

  } catch(error) {

    handleException(error);

    return false;
  }
}
