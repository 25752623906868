import fetchRamsSignings from '../Services/fetchRamsSignings'
import { setFilters } from '../State/filters'
import { setPagination } from '../State/pagination'

export default (filter, value) => (dispatch) => {

  dispatch(setFilters({ [filter]: value }))

  dispatch(setPagination({ pageNumber: 1 }))

  dispatch(fetchRamsSignings())

}
