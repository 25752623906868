import { combineReducers } from 'redux'

import { reducer as ramsSignings } from './State/ramsSignings'
import { reducer as fetching } from './State/fetching'
import { reducer as searchTerm } from './State/searchTerm'
import { reducer as filters } from './State/filters'
import { reducer as sorting } from './State/sorting'
import { reducer as pagination } from './State/pagination'
import { reducer as ramsSigningModal } from './State/ramsSigningModal'

export default combineReducers({
  ramsSignings,
  fetching,
  searchTerm,
  filters,
  sorting,
  pagination,
  ramsSigningModal,
})
