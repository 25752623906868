import { message } from 'antd'
import { deleteEmail } from '../../../HttpRequests/emails'
import { getProps } from '../props'
import handleException from '../../../Core/Helpers/handleException'

export default () => async () => {

  const { id, onClose, didDelete } = getProps()

  try {

    await deleteEmail({ id })

    message.success('Email Deleted')

    onClose()

    if (didDelete) didDelete()

  } catch (error) {

    handleException(error)

  }

}
