exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2l0DXW50h9fq7saRuHZWQR tbody tr {\n  cursor: pointer;\n}\n\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/Emails/Components/Table.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;CACjB","file":"Table.css","sourcesContent":[".table tbody tr {\n  cursor: pointer;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "_2l0DXW50h9fq7saRuHZWQR"
};